import { Typography } from '@mui/material';
import Link from 'next/link';

import { Rutas } from '@/constants/rutas';

import AlineacionesActivityIcon from '../home/icons/alineaciones-activity-icon';

import ActivityEventsIcon from './icons/activity-icon';
import AnalizaTuPlantillaIcon from './icons/analiza-tu-plantilla-icon';
import ApercibidosStatusIcon from './icons/apercibidos-status-icon';
import AppIcon from './icons/app-icon';
import CalendarioFantasyAdminIcon from './icons/calendario-fantasy-admin';
import LesionadosIcon from './icons/lesionados-icon';
import MercadoIcon from './icons/mercado-icon';
import NewsIcon from './icons/news-icon';
import NoticiasFlashIcon from './icons/noticias-flash-icon';
import OraculoIcon from './icons/oraculo-icon';
import PublicTierlistIcon from './icons/public-tierlist-icon';
import PujaIdealIcon from './icons/puja-idea-icon';
import PuntuacionesFantasyIcon from './icons/puntuaciones-fantasy-icon';
import SancionadosIcon from './icons/sancionados-icon';
import DudaStatusIcon from './icons/status-icons/duda-status-icon';

const items = [
    { icon: <NewsIcon iconWidth={18} iconHeight={18} />, text: 'Noticias', route: Rutas.NOTICIAS },
    { icon: <MercadoIcon iconWidth={18} iconHeight={18} color="#1d6fb1" />, text: 'Mercado', route: Rutas.MERCADO },
    { icon: <PujaIdealIcon iconWidth={18} iconHeight={18} color="#1d6fb1" />, text: 'Puja ideal', route: Rutas.PUJA_FANTASY },
    { icon: <OraculoIcon />, text: 'Oráculo', route: Rutas.ORACULO_FANTASY },
    { icon: <PublicTierlistIcon iconWidth={18} iconHeight={18} />, text: 'Tierlists', route: Rutas.TIER_LIST_PUBLIC },
    { icon: <ActivityEventsIcon width={18} height={18} color="#1d6fb1" />, text: 'Actividades', route: Rutas.ACTIVIDADES },
    { icon: <NoticiasFlashIcon color="#1a892d" iconHeight={18} iconWidth={18} />, text: 'Flash', route: Rutas.NOTICIAS_FLASH },
    { icon: <AlineacionesActivityIcon color="#1d6fb1" height={18} width={18} />, text: 'Alineaciones', route: Rutas.ALINEACIONES_PROBABLES },
    { icon: <PuntuacionesFantasyIcon width={18} height={18} />, text: 'Puntos', route: Rutas.PUNTUACIONES_FANTASY },
    { icon: <LesionadosIcon />, text: 'Lesionados', route: Rutas.JUGADORES_LESIONADOS },
    { icon: <SancionadosIcon iconHeight={18} iconWidth={18} />, text: 'Sancionados', route: Rutas.JUGADORES_SANCIONADOS },
    { icon: <DudaStatusIcon />, text: 'Dudas', route: Rutas.JUGADORES_DUDAS },
    { icon: <ApercibidosStatusIcon iconHeight={18} iconWidth={18} />, text: 'Apercibidos', route: Rutas.JUGADORES_APERCIBIDOS },
    { icon: <AnalizaTuPlantillaIcon iconHeight={18} iconWidth={18} />, text: 'Plantillas', route: Rutas.ANALIZA_TU_PLANTILLA },
    { icon: <CalendarioFantasyAdminIcon iconHeight={18} iconWidth={18} />, text: 'Calendario', route: Rutas.CALENDARIO_FANTASY },
    { icon: <AppIcon iconHeight={18} iconWidth={18} />, text: 'Aplicación', route: Rutas.INSTALL_APP, external: true },
];

const SecondaryNavigationComponent = () => {
    return (
        <div className="jugadores-navigation">
            {items.map((item, index) => (
                <Link key={index} href={item.route} className="jugadores-navigation__item">
                    {item.icon}
                    <Typography className="jugadores-navigation__item-text">{item.text}</Typography>
                </Link>
            ))}
        </div>
    );
};

export default SecondaryNavigationComponent;
