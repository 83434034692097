export const REDUCERS = {
    UPDATE_APP: 'update_app',
    UPDATE_SEASON: 'update_season',
    UPDATE_LEAGUE: 'update_league',
    LOGGED_IN_USER: 'logged_in_user',
    UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTIOn',
    LOGGED_OUT_IN: 'logged_out_in',
    GET_DATA_FROM_COOKIES: 'get_data_from_cookies',
    LOGGED_OUT_USER: 'logged_out_user',
    UPDATE_USER: 'update-user',
    UPDATE_FIXTURES_ROUND: 'update_fixtures_round',
    UPDATE_FECHAS_CLAUSULABLES: 'UPDATE_FECHAS_CLAUSULABLES',
    UPDATE_CONFIG: 'update_config',
    UPDATE_ADS: 'update-ads',
    UPDATE_CACHE: 'update-cache',
    GET_FANTASY_PLAYERS: 'get_fantasy_players',
    UPDATE_RANKING: 'update_ranking',
    GET_FANTASY_SQUAD: 'get_fantasy_squad',
    UPDATE_FANTASY_FORMATION: 'update_fantasy_formation',
    SHOW_SPINNER: 'SHOW_SPINNER',
    HIDE_SPINNER: 'HIDE_SPINNER',
    UPDATE_CONFIGURATION: 'UPDATE_CONFIGURATION',
    GET_CONFIGURATION: 'GET_CONFIGURATION',
    SHOW_PLAYER_PROFILE: 'SHOW_PLAYER_PROFILE',
    HIDE_PLAYER_PROFILE: 'HIDE_PLAYER_PROFILE',
    SET_ALL_PLAYERS: 'SET_ALL_PLAYERS',
    UPDATE_PREFERENCES: 'UPDATE_PREFERENCES',
    GET_PREFERENCES: 'GET_PREFERENCES',
    GET_VIDEOS: 'GET_VIDEOS',
    UPDATE_VIDEOS: 'UPDATE_VIDEOS',
    GET_VIDEO: 'GET_VIDEO',
    UPDATE_ACTIVITIES: 'UPDATE_ACTIVITIES',
    SHOW_FANTASY_HISTORIC: 'SHOW_FANTASY_HISTORIC',
    HIDE_FANTASY_HISTORIC: 'HIDE_FANTASY_HISTORIC',
    SHOW_MARKET_PROJECTION: 'SHOW_MARKET_PROJECTION',
    SHOW_MASTER_ACCIONES_PLAYER_ID: 'SHOW_MASTER_ACCIONES_PLAYER_ID',
    SHOW_PLAYER_CALENDAR: 'SHOW_PLAYER_CALENDAR',
    UPDATE_ONCE_TIPO: 'UPDATE_ONCE_TIPO',
};
