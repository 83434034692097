const OwnGoalsIcon = ({ width, height }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width ?? 17} height={height ?? 17} viewBox="0 0 36 36">
            <circle cx="18" cy="18" r="18" fill="#F5F8FA" />
            <path
                fill="#CCD6DD"
                d="M18 11a1 1 0 0 1-1-1V3a1 1 0 0 1 2 0v7a1 1 0 0 1-1 1zm-6.583 4.5a.99.99 0 0 1-.302-.047l-8.041-2.542a1 1 0 1 1 .603-1.907l8.042 2.542a1 1 0 0 1-.302 1.954zm13.625-.291a1.001 1.001 0 0 1-.278-1.961l6.75-1.958a1 1 0 0 1 .556 1.921l-6.75 1.958a.989.989 0 0 1-.278.04zm2.001 14.958a1 1 0 0 1-.803-.403l-5.459-7.333a1 1 0 1 1 1.604-1.194l5.459 7.333a1 1 0 0 1-.801 1.597zm-18.294-.083a1 1 0 0 1-.82-1.571l5.125-7.375a1 1 0 1 1 1.642 1.141l-5.125 7.375a.996.996 0 0 1-.822.43zM3.5 27.062c-.44 0-.844-.293-.965-.738L.347 18.262a1 1 0 1 1 1.931-.523l2.188 8.062a.998.998 0 0 1-.966 1.261zM22 34h-9a1 1 0 1 1 0-2h9a1 1 0 1 1 0 2zm10.126-6.875a.999.999 0 0 1-.971-1.239l2.125-8.625a1 1 0 1 1 1.941.479l-2.125 8.625c-.113.455-.521.76-.97.76zM30.312 7.688a.998.998 0 0 1-.5-.134L22.25 3.179a1 1 0 0 1-.364-1.367a.998.998 0 0 1 1.366-.365l7.562 4.375a1 1 0 0 1-.502 1.866zm-24.811 0a1 1 0 0 1-.584-1.813l6.188-4.438a1.001 1.001 0 0 1 1.167 1.625L6.083 7.5a1 1 0 0 1-.582.188z"
            />
            <path
                fill="red"
                d="m25.493 13.516l-7.208-5.083a1.002 1.002 0 0 0-1.161.006l-7.167 5.167a1 1 0 0 0-.375 1.091l2.5 8.583a1 1 0 0 0 .96.72H22a1 1 0 0 0 .948-.681l2.917-8.667a1 1 0 0 0-.372-1.136zM1.292 19.542a1 1 0 0 0 .872-.51l3.375-6a1 1 0 0 0 .108-.291L6.98 6.2A.999.999 0 0 0 6 5h-.428C2.145 8.277 0 12.884 0 18c0 .266.028.525.04.788l.602.514c.182.156.413.24.65.24zm9.325-16.547a.748.748 0 0 0 .553.412l6.375 1.042a.823.823 0 0 0 .243 0l6.084-1a.748.748 0 0 0 .613-.889l-.292-1.443A17.912 17.912 0 0 0 18 0c-2.425 0-4.734.486-6.845 1.356l-.521.95a.752.752 0 0 0-.017.689zm20.517 2.724l-1.504-.095a.758.758 0 0 0-.609.249a.741.741 0 0 0-.175.63l1.167 6.198a.666.666 0 0 0 .093.224c1.492 2.504 3.152 5.301 3.381 5.782c.024.084.062.079.114.151c.14.195.372.142.612.142h.007c.198 0 .323.094 1.768-.753c.001-.083.012-.164.012-.247c0-4.753-1.856-9.064-4.866-12.281zM14.541 33.376a.755.755 0 0 0-.191-.544l-4.5-5a.746.746 0 0 0-.211-.163c-5.885-3.069-5.994-3.105-6.066-3.13a.8.8 0 0 0-.242-.039c-.537 0-.695.065-1.185 2.024a18.044 18.044 0 0 0 10.644 8.703l1.5-1.333a.753.753 0 0 0 .251-.518zm17.833-8.567a.747.747 0 0 0-.592.005l-6.083 2.667a.738.738 0 0 0-.274.205l-4.25 5.083a.752.752 0 0 0 .1 1.062c.683.559 1.261 1.03 1.767 1.44a18.017 18.017 0 0 0 10.454-8.146l-.712-1.889a.749.749 0 0 0-.41-.427z"
            />
        </svg>
    );
};

export default OwnGoalsIcon;
