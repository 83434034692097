import { getPlayerIcon } from '@/helpers/players-helper';

import CustomImageComponent from './custom/custom-image';
import ApercibidosStatusIcon from './icons/apercibidos-status-icon';
import PositionBox from './position-box';

const PlayerFullCard = ({
    teamImage,
    positionId,
    playerImage,
    status,
    statusLevel,
    handleClick,
    width = 100,
    height = 100,
    apercibido,
}) => {
    const handleLocalClick = () => {
        if (handleClick) {
            handleClick();
        }
    };

    return (
        <div
            onClick={handleLocalClick}
            style={{
                textAlign: 'center',
                cursor: handleClick ? 'pointer' : 'default',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '120px',
            }}
        >
            {/* Imagen del Jugador */}
            <div
                style={{
                    position: 'relative',
                    borderRadius: '8px',
                    width: `${width}`,
                }}
            >
                <CustomImageComponent src={playerImage} alt="jugador" width={width} height={height} />

                {/* Posición (Top-Left) */}
                <div
                    style={{
                        position: 'absolute',
                        top: '4px',
                        left: '4px',
                        borderRadius: '4px',
                        padding: '2px 4px',
                        fontSize: '10px',
                        color: '#fff',
                    }}
                >
                    <PositionBox
                        positionId={positionId}
                        styles={{
                            width: '15px',
                            height: '15px',
                            fontSize: '10px',
                        }}
                    />
                </div>

                {/* Equipo (Top-Right) */}
                <div
                    style={{
                        position: 'absolute',
                        top: '0px',
                        right: '8px',
                        borderRadius: '4px',
                        padding: '2px',
                    }}
                >
                    <CustomImageComponent src={teamImage} alt="equipo" width={15} height={15} />
                </div>

                {/* Estado (Bottom-Left) */}
                <div
                    style={{
                        position: 'absolute',
                        top: `${width - 20}px`,
                        left: '0px',
                        borderRadius: '4px',
                        padding: '2px',
                    }}
                >
                    {getPlayerIcon(status, statusLevel)}
                </div>

                {/* Apercibido (Opcional, abajo a la derecha) */}
                <div
                    style={{
                        position: 'absolute',
                        bottom: '4px',
                        right: '4px',
                    }}
                >
                    <ApercibidosStatusIcon hide={!apercibido} />
                </div>
            </div>
        </div>
    );
};

export default PlayerFullCard;
