export const Pages = {
    ESTADISTICAS_FANTASY_JUGADORES: 'ESTADISTICAS_FANTASY_JUGADORES',
    LIDERES_ESTADISTICAS: 'LIDERES_ESTADISTICAS',
    INICIO: 'INICIO',
    MERCADO: 'MERCADO',
    ANALIZA_TU_PLANTILLA: 'ANALIZA_TU_PLANTILLA',
    NOTICIA: 'NOTICIA',
    NOTICIAS: 'NOTICIAS',
    JUGADOR: 'JUGADOR',
    COMPARADOR_DE_JUGADORES: 'COMPARADOR_DE_JUGADORES',
    LESIONADOS_SANCIONADOS_APERCIBIDOS: 'LESIONADOS_SANCIONADOS_APERCIBIDOS',
    CLASIFICACION: 'CLASIFICACION',
    ONCE_IDEAL: 'ONCE_IDEAL',
    LANZADORES_PENALTIS: 'LANZADORES_PENALTIS',
    ESTADISTICAS_DEL_JUGADOR: 'ESTADISTICAS_DEL_JUGADOR',
    ESTADISTICAS_DEL_JUGADOR_DEFAULT: 'ESTADISTICAS_DEL_JUGADOR_DEFAULT',
    LIDERES_POR_JORNADAS: 'LIDERES_POR_JORNADAS',
    MEJORES_RACHAS: 'MEJORES_RACHAS',
    INICIA_SESION: 'INICIA_SESION',
    REGISTRATE: 'REGISTRATE',
    MIS_PLANTILLAS: 'MIS_PLANTILLAS',
    EDITAR_PLANTILLA: 'EDITAR_PLANTILLA',
    CREAR_PLANTILLA: 'CREAR_PLANTILLA',
    TERMINOS_CONDICIONES: 'TERMINOS_CONDICIONES',
    OLVIDASTE_TU_CONTRASENA: 'OLVIDASTE_TU_CONTRASENA',
    POLITICAS_PRIVACIDAD: 'POLITICAS_PRIVACIDAD',
    CONTACTANOS: 'CONTACTANOS',
    PARTIDO: 'PARTIDO',
    CALENDARIO_FANTASY: 'CALENDARIO_FANTASY',
    EQUIPO: 'EQUIPO',
    REESTABLECER_CONTRASENA: 'REESTABLECER_CONTRASENA',
    ANALISIS_PLANTILLA: 'ANALISIS_PLANTILLA',
    ENCUESTA_DE_SATISFACCION: 'ENCUESTA_DE_SATISFACCION',
    PERFIL_DE_USUARIO: 'PERFIL_DE_USUARIO',
    ALINEACIONES_PROBABLES: 'ALINEACIONES_PROBABLES',
    FANTASY_MANAGER: 'FANTASY_MANAGER',
    PREDICTING_MANAGER: 'PREDICTING_MANAGER',
    PREDICTING_MANAGER_RANKING: 'PREDICTING_MANAGER_RANKING',
    ADMINISTRADOR_JUGADORES: 'ADMINISTRADOR_JUGADORES',
    PREDICTING_MANAGER_ALINEACIONES: 'PREDICTING_MANAGER_ALINEACIONES',
    PREDICTING_MANAGER_RULES: 'PREDICTING_MANAGER_RULES',
    COLABORADORES: 'COLABORADORES',
    COLABORADORES_NOTICIAS: 'COLABORADORES_NOTICIAS',
    PARTE_MEDICO: 'PARTE_MEDICO',
    JUGADORES: 'JUGADORES',
    JUGADOR: 'JUGADOR',
    QUINIELA_CLASIFICACION: 'QUINIELA_CLASIFICACION',
    QUINIELA_REGLAS_DEL_JUEGO: 'QUINIELA_REGLAS_DEL_JUEGO',
    ADMINISTRADOR_QUINIELA: 'ADMINISTRADOR_QUINIELA',
    QUINIELA_REGLAS_DEL_JUEGO: 'QUINIELA_REGLAS_DEL_JUEGO',
    QUINIELA_CLASIFICACION: 'QUINIELA_CLASIFICACION',
    QUINIELA_JUEGO: 'QUINIELA_JUEGO',
    PUJA_FANTASY: 'PUJA_FANTASY',
    PUJA_FANTASY_BIWENGER: 'PUJA_FANTASY_BIWENGER',
    JUGADORES_SANCIONADOS: ' JUGADORES_SANCIONADOS',
    JUGADORES_LESIONADOS: 'JUGADORES_LESIONADOS',
    JUGADORES_DUDAS: 'JUGADORES_DUDAS',
    JUGADORES_APERCIBIDOS: 'JUGADORES_APERCIBIDOS',
    NOTICIAS_FLASH: 'NOTICIAS_FLASH',
    MERCADO_FICHAJES: 'MERCADO_FICHAJES',
    NOTICIAS_FLASH_ADMIN: 'NOTICIAS_FLASH_ADMIN',
    CALENDARIO_COMPETICIONES: 'CALENDARIO_COMPETICIONES',
    ADMINISTRADOR_CONVOCATORIAS: 'ADMINISTRADOR_CONVOCATORIAS',
    CONVOCATORIAS: 'CONVOCATORIAS',
    VIRUS_FIFA: 'VIRUS_FIFA',
    APUESTAS_DEPORTIVAS: 'APUESTAS_DEPORTIVAS',
    MERCADO_BIWENGER: 'MERCADO_BIWENGER',
    ESTADISTICAS_BIWENGER: 'ESTADISTICAS_BIWENGER',
    CHAT: 'CHAT',
    REGLAS_CHAT: 'REGLAS_CHAT',
    INSTALL_APP: 'INSTALL_APP',
    PREMIOS_Y_SORTEOS: 'PREMIOS_Y_SORTEOS',
    ACTIVIDADES: 'ACTIVIDADES',
    SALON_DE_LA_FAMA: 'SALON_DE_LA_FAMA',
    CHOLLOS_FANTASY_BIWENGER: 'CHOLLOS_FANTASY_BIWENGER',
    CHOLLOS_FANTASY_RELEVO: 'CHOLLOS_FANTASY_RELEVO',
    ONCE_TIPO: 'ONCE_TIPO',
    QUEDADA_FANTASY: 'QUEDADA_FANTASY',
    CALENDARIO_ADMIN: 'CALENDARIO_ADMIN',
    COMPARTIR_PLANTILLA: 'COMPARTIR_PLANTILLA',
    ADMINISTRADOR_PARTIDOS: 'ADMINISTRADOR_PARTIDOS',
    TIER_LIST: 'TIER_LIST',
    TIER_LIST_PUBLIC: 'TIER_LIST_PUBLIC',
    EDIT_TIER_LIST: 'EDIT_TIER_LIST',
    NUEVA_TIER_LIST: 'NUEVA_TIER_LIST',
    VISUALIZAR_TIER_LIST: 'VISUALIZAR_TIER_LIST',
    DELETE_TIER_LIST: 'DELETE_TIER_LIST',
    FANTASY_RELEVO_FALLECIO: 'FANTASY_RELEVO_FALLECIO',
    ADMINISTRADOR_CONFIGURACIONES: 'ADMINISTRADOR_CONFIGURACIONES',
    ORACULO_FANTASY: 'ORACULO_FANTASY',
    KIT_DIGITAL: 'KIT_DIGITAL',
    SITIOS_RECOMENDADOS: 'SITIOS_RECOMENDADOS',
    SUSCRIPCIONES: 'SUSCRIPCIONES',
    PAGO_EXITOSO: 'PAGO_EXITOSO',
    PAGO_CANCELADO: 'PAGO_CANCELADO',
    CREADORES_CONTENIDO: 'CREADORES_CONTENIDO',
    PUNTUACIONES_FANTASY: 'PUNTUACIONES_FANTASY',
    PREPARADOR_JORNADA: 'PREPARADOR_JORNADA',
    MERCADO_EQUIPOS: 'MERCADO_EQUIPOS',
    NOVEDADES: 'NOVEDADES',
    PREMIUM: 'PREMIUM',
};

export const Rutas = {
    ESTADISTICAS_FANTASY_JUGADORES: '/fantasy-la-liga/estadisticas',
    MERCADO: '/fantasy-la-liga/mercado',
    CALENDARIO_FANTASY: '/fantasy-la-liga/calendario',
    ADMIN: '/administrador/fantasy',
    CONFIGURATION: '/configuracion',
    ANALIZA_TU_PLANTILLA: '/fantasy-la-liga/analisis-plantilla',
    LIDERES_ESTADISTICAS: '/fantasy-la-liga/jugadores-lideres-estadisticas',
    COMPARADOR_DE_JUGADORES: '/fantasy-la-liga/comparador-de-jugadores',
    CLASIFICACION: '/clasificacion',
    ESTADISTICAS_DEL_JUGADOR: '/jugadores',
    MEJORES_RACHAS: '/fantasy-la-liga/mejores-rachas-de-jugadores',
    INICIA_SESION: '/iniciar-sesion',
    REGISTRATE: '/crear-cuenta',
    MIS_PLANTILLAS: '/mis-plantillas-fantasy',
    EDITAR_PLANTILLA: '/mis-plantillas-fantasy/editar-plantilla',
    CREAR_PLANTILLA: '/mis-plantillas-fantasy/crear-plantilla',
    TERMINOS_CONDICIONES: '/terminos-y-condiciones',
    POLITICAS_PRIVACIDAD: '/politicas-de-privacidad',
    CONTACTANOS: '/contactanos',
    PARTIDO: '/partido',
    INICIO: '/',
    REESTABLECER_CONTRASENA: '/reestablecer-contrasena',
    PLANTILLA: '/fantasy-la-liga/analisis-plantilla',
    ENCUESTA_DE_SATISFACCION: '/encuesta-de-satisfaccion',
    PERFIL_DE_USUARIO: '/perfil-de-usuario',
    OLVIDASTE_TU_CONTRASENA: '/olvidaste-tu-contrasena',
    ALINEACIONES_PROBABLES: '/la-liga/alineaciones-probables',
    PREDICTING_MANAGER: '/predicting-manager/crear-alineaciones-probables',
    PREDICTING_MANAGER_RANKING: '/predicting-manager/clasificacion',
    ADMINISTRADOR_JUGADORES: '/administrador/jugadores',
    ADMINISTRADOR_IMAGENES: '/administrador/imagenes',
    BUSCADOR_JUGADORES: '/administrador/buscador',
    ADMIN_ALINEACIONES_PROBABLES: '/administrador/admin-alineaciones-probables',
    PARTE_MEDICO: '/administrador/parte-medico',
    FANTASY_MANAGER: '/fantasy-manager',
    PREDICTING_MANAGER_ALINEACIONES: '/predicting-manager/alineaciones-de-managers',
    PREDICTING_MANAGER_RULES: '/predicting-manager/reglas-del-juego',
    NOTICIAS: '/noticias',
    ASESORIAS_FANTASY: '/asesorias-fantasy',
    FLASHSCORE: 'https://www.flashscore.es/',
    EQUIPO: '/equipo',
    COLABORADORES: '/colaboradores',
    COLABORADORES_NOTICIAS: '/colaboradores',
    JUGADORES: '/jugadores',
    ADMINISTRADOR_QUINIELA: '/administrador/quiniela-analitica',
    QUINIELA_REGLAS_DEL_JUEGO: '/quiniela-analitica/reglas-del-juego',
    QUINIELA_CLASIFICACION: '/quiniela-analitica/clasificacion',
    QUINIELA_JUEGO: '/quiniela-analitica/votaciones',
    PUJA_FANTASY: '/fantasy-la-liga/puja-ideal',
    PUJA_FANTASY_BIWENGER: '/biwenger/puja-ideal',
    JUGADORES_SANCIONADOS: '/la-liga/jugadores-sancionados',
    JUGADORES_LESIONADOS: '/la-liga/jugadores-lesionados',
    JUGADORES_DUDAS: '/la-liga/jugadores-dudas',
    JUGADORES_APERCIBIDOS: '/la-liga/jugadores-apercibidos',
    NOTICIAS_FLASH: '/noticias-flash',
    MERCADO_FICHAJES: '/mercado-fichajes',
    NOTICIAS_FLASH_ADMIN: '/administrador/noticias-flash-admin',
    CALENDARIO_COMPETICIONES: '/la-liga/calendario-competiciones',
    CONFIGURATION: '/administrador/configuracion',
    ADMINISTRADOR_CONVOCATORIAS: '/administrador/convocatorias',
    CONVOCATORIAS: '/convocatorias',
    VIRUS_FIFA: '/selecciones/convocatorias-jugadores/virus-fifa',
    APUESTAS_DEPORTIVAS: '/apuestas-deportivas',
    MERCADO_FICHAJES_EN_VIVO:
        '/noticias/mercado-de-fichajes-de-invierno-de-la-liga-en-vivo-2024-01-10-10-00-49',
    MERCADO_BIWENGER: '/biwenger/mercado',
    ESTADISTICAS_BIWENGER: '/biwenger/estadisticas',
    ACCOUNTS: '/administrador/accounts',
    BESOCCER: '/administrador/besoccer',
    INSTALL_APP: '/instala-la-app',
    PREMIOS_Y_SORTEOS: '/premios-y-sorteos',
    ACTIVIDADES: '/actividades',
    SALON_DE_LA_FAMA: '/salon-de-la-fama',
    CHOLLOS_FANTASY_BIWENGER: '/chollos-fantasy/biwenger',
    CHOLLOS_FANTASY_RELEVO: '/chollos-fantasy/fantasy-relevo',
    ONCE_TIPO: '/administrador/once-tipo',
    QUEDADA_FANTASY: '/quedada-fantasy',
    CALENDARIO_ADMIN: '/administrador/calendario-admin',
    LANZADORES_PENALTIS: '/lanzadores-de-penaltis',
    COMPARTIR_PLANTILLA: '/compartir-plantilla',
    ADMINISTRADOR_PARTIDOS: '/administrador/administrador-partidos',
    ADMINISTRADOR_CONFIGURACIONES: '/administrador/configuraciones',
    TIER_LIST: '/tierlists',
    TIER_LIST_PUBLIC: '/tierlists/fantasy',
    TIER_LIST_ADMIN: '/tierlists/admin',
    EDIT_TIER_LIST: '/tierlists/editar',
    VISUALIZAR_TIER_LIST: '/tierlists/ver',
    DELETE_TIER_LIST: '/tierlists',
    NUEVA_TIER_LIST: '/tierlists/crear-nueva-tierlist',
    NUEVA_TIER_LIST_ADMIN: '/tierlists/crear-nueva-admin-tierlist',
    FANTASY_RELEVO_FALLECIO: '/fantasy-relevo-fallecio',
    ORACULO_FANTASY: '/oraculo-fantasy',
    KIT_DIGITAL: '/kit-digital',
    SITIOS_RECOMENDADOS: '/sitios-recomendados',
    SUSCRIPCIONES: '/suscripciones',
    PAGO_EXITOSO: '/pago-exitoso',
    PAGO_CANCELADO: '/pago-cancelado',
    CREADORES_CONTENIDO: '/colaboradores/creadores-de-contenido',
    PUNTUACIONES_FANTASY: '/puntuaciones-la-liga-fantasy-jornada',
    PREPARADOR_JORNADA: '/preparador-jornada',
    MERCADO_EQUIPOS: '/fantasy-la-liga/mercado-equipos',
    NOVEDADES: '/novedades-analitica-fantasy',
    PREMIUM: '/premium',
    PREMIUM_ANALYSIS: '/premium/analysis',
};
