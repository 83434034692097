import { useContext, useState, useEffect, Fragment } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, Drawer, Typography } from '@mui/material';
import { useRouter } from 'next/router';

import { REDUCERS } from '@/constants/reducers';
import { Rutas } from '@/constants/rutas';
import { Context } from '@/context';
import { navLinks } from '@/data/nav-items';
import { fetchPlayersAndDispatch } from '@/services/playersService';

import ReactSelect from './custom/select';
import { getIcon } from './icons-factory';
import SocialComponent from './social';

const LeftDrawerComponent = ({ anchor, open, handleClose }) => {
    const { state, dispatch } = useContext(Context);
    const { user, playerProfile } = state;
    const router = useRouter();
    const [navItems, setNavItems] = useState(navLinks);
    const [loadingPlayers, setLoadingPlayers] = useState(false);
    const [filteredPlayers, setFilteredPlayers] = useState([]);
    const [playersLoaded, setPlayersLoaded] = useState(false);

    const showResult = 30;

    const toggleDrawer = () => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        handleClose();
    };

    const handleNavigation = (link, e) => {
        e.preventDefault();

        if (link.id === 'app') {
            window.location.href = link.path;
        }

        if (link.navigate) {
            handleClose();
            router.push(link.path);
        } else {
            let navItemsCopy = [...navItems];
            let navItem = navItemsCopy.find((n) => n.id === link.id);
            navItem.navOpen = !navItem.navOpen;
            setNavItems(navItemsCopy);
        }
    };

    const handleNavigationLoginRegister = (route, e) => {
        e.preventDefault();
        handleClose();
        router.push(route);
    };

    const logout = () => {
        dispatch({ type: REDUCERS.LOGGED_OUT_USER });
    };

    const handleGoToUserProfile = () => {
        handleClose();
        router.push(Rutas.PERFIL_DE_USUARIO);
    };

    const onChangePlayerSelected = (event) => {
        handleClose();
        if (event?.value) {
            const url = `${Rutas.JUGADORES}/${event?.value}`;
            router.push(url);
        }
    };

    // Función que se ejecuta al hacer foco en el select por primera vez
    const handleFocusSelect = async () => {
        if (playerProfile?.allPlayers?.length) {
            // Si ya tenemos jugadores en el state, simplemente filtrar los primeros resultados
            setFilteredPlayers(playerProfile.allPlayers.slice(0, showResult));
            setPlayersLoaded(true); // Forzar re-render del select
            return;
        }

        setLoadingPlayers(true);

        try {
            // Dispatch para cargar jugadores si no están en el estado global
            await fetchPlayersAndDispatch(dispatch, playerProfile);
            setPlayersLoaded(true); // Indicar que los jugadores han sido cargados
        } catch (error) {
            console.error('Error fetching players:', error);
        } finally {
            setLoadingPlayers(false);
        }
    };

    // Función que filtra los jugadores según la búsqueda del usuario
    const handleInputChange = (inputValue) => {
        if (!playerProfile?.allPlayers?.length) return;

        const filtered = playerProfile.allPlayers
            .filter((player) => player.name.toLowerCase().includes(inputValue.toLowerCase()))
            .slice(0, showResult); // Limitar los resultados a 30 jugadores

        setFilteredPlayers(filtered);
    };

    useEffect(() => {
        if (playersLoaded) {
            setFilteredPlayers(playerProfile?.allPlayers?.slice(0, showResult));
        }
    }, [playersLoaded, playerProfile]); // Re-renderiza cuando los jugadores son cargados

    const DrawerContent = () => (
        <div className={`left-drawer-component`}>
            <nav className="left-drawer-component-content">
                <SocialComponent handleClose={handleClose} />
                <div className="search-players-menu">
                    <ReactSelect
                        id={`search-players-menu`}
                        options={filteredPlayers?.map((player) => ({
                            label: player.name,
                            value: `${player.slug}`,
                        }))}
                        onFocus={handleFocusSelect} // Cargar jugadores al hacer foco
                        onChange={onChangePlayerSelected}
                        onInputChange={handleInputChange} // Filtrar jugadores al escribir
                        placeholder={loadingPlayers ? 'Cargando jugadores...' : 'Selecciona un jugador'}
                    />
                </div>
                <ul className="left-drawer-component-content__items">
                    {navItems
                        ?.filter((x) => x.hideInMobile !== true)
                        .map((link, index) => (
                            <li
                                key={index}
                                className={`left-drawer-component-content__items__item ${
                                    link.isAdmin && !user.isAdmin ? 'd-none' : ''
                                } ${link.logged && !user.logged ? 'd-none' : ''}`}
                            >
                                <div
                                    className="nav-item-row nav-item-row-parent"
                                    onClick={(e) => handleNavigation(link, e)}
                                >
                                    {link.new && (
                                        <Typography className="new-sections-mobile">Nuevo</Typography>
                                    )}
                                    <div className="nav-item-row nav-item-row-parent__left">
                                        <button className="left-drawer-component-content__items__item__btn">
                                            {getIcon(link.id, 25, 25)}
                                            <Typography style={{ fontSize: '16px' }}>
                                                {link.mobileText ?? link.title}
                                            </Typography>
                                        </button>
                                    </div>
                                    {link.subMenu && (
                                        <div style={{ color: 'white' }}>
                                            {link.navOpen ? (
                                                <KeyboardArrowUpIcon style={{ cursor: 'pointer' }} />
                                            ) : (
                                                <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} />
                                            )}
                                        </div>
                                    )}
                                </div>
                                {link.subMenu && link.navOpen && (
                                    <ul className={`left-drawer-component-content__items-sublist `}>
                                        {link.subMenu
                                            ?.filter((x) => x.hideInMobile !== true)
                                            .map((subLink, index) => (
                                                <li
                                                    key={index}
                                                    className={`left-drawer-component-content__items-sublist-item nav-item-row nav-item-row-sub ${
                                                        subLink.isAdmin && !user.isAdmin ? 'd-none' : ''
                                                    } ${subLink.logged && !user.logged ? 'd-none' : ''}`}
                                                    onClick={(e) => handleNavigation(subLink, e)}
                                                    style={{ cursor: 'pointer', position: 'relative' }}
                                                >
                                                    {getIcon(subLink.id)}
                                                    <Typography>{subLink.title}</Typography>
                                                    {subLink.new && (
                                                        <Typography className="new-sections-mobile">
                                                            Nuevo
                                                        </Typography>
                                                    )}
                                                </li>
                                            ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                </ul>

                {!user.logged && (
                    <div className="left-drawer-component__profile">
                        <Button
                            className="login-register-container__btn"
                            variant="outlined"
                            color="info"
                            onClick={(e) => handleNavigationLoginRegister(Rutas.INICIA_SESION, e)}
                        >
                            Inicia sesión
                        </Button>
                        <Button
                            className="login-register-container__btn"
                            variant="outlined"
                            color="info"
                            onClick={(e) => handleNavigationLoginRegister(Rutas.REGISTRATE, e)}
                        >
                            Regístrate
                        </Button>
                    </div>
                )}

                {user.logged && (
                    <div className="left-drawer-component__profile">
                        <Button
                            className="login-register-container__btn"
                            variant="outlined"
                            color="info"
                            onClick={handleGoToUserProfile}
                        >
                            <Typography>Perfil de usuario</Typography>
                        </Button>
                        <Button
                            className="login-register-container__btn"
                            variant="outlined"
                            color="info"
                            onClick={logout}
                        >
                            <Typography>Cerrar sesión</Typography>
                        </Button>
                    </div>
                )}
            </nav>
        </div>
    );

    return (
        <Fragment key={anchor}>
            <Drawer anchor={anchor} open={open} onClose={toggleDrawer(anchor, false)}>
                {DrawerContent(anchor)}
            </Drawer>
        </Fragment>
    );
};

export default LeftDrawerComponent;
