import CustomImageComponent from './custom/custom-image';

const LogoImage = ({ styles, width }) => {
    return (
        <CustomImageComponent
            className="image-logo__image"
            src={`${process.env.NEXT_PUBLIC_IMAGES}/analiticafantasy.png?width=${width ? width * 1.5 : 105}`}
            alt="logo"
            width={width ?? 70}
            height={width ?? 70}
            priority={true}
            style={styles}
        />
    );
};

export default LogoImage;
