import { useContext } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import { Typography } from '@mui/material';
import Link from 'next/link';

import { Context } from '@/context';
import { navLinks } from '@/data/nav-items';

import CalendarIcon from './icons/calendar-icon';
import Logo from './logo';

const DesktopNavigationComponent = ({ handlePartidosMenu, handleHamburguerMenu }) => {
    const { state } = useContext(Context);
    const { user } = state;

    const renderSubMenu = (link) => (
        <ul className="dropdown-content desktop-navigation-container__subcontainer__menu-container__list__menu-item__submenu__dropdown-menu">
            {link.subMenu
                .filter((subLink) => !!subLink.logged === user.logged || user.logged === true)
                .map((subLink, index) => (
                    <Link href={subLink.path} key={`sub-menu${index}`} passHref>
                        <li
                            className={`dropdown-item${subLink.id === 'puja-fantasy' ? ' pujaid' : ''}`}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: '5px',
                                zIndex: 999999,
                            }}
                        >
                            {/* <div>{getIcon(subLink.id)}</div> */}
                            <Typography style={{ fontSize: '14px' }}>{subLink.title}</Typography>
                        </li>
                    </Link>
                ))}
        </ul>
    );

    return (
        <section className="desktop-navigation-container">
            <div className="desktop-navigation-container__subcontainer container">
                <div className="desktop-navigation-container__logo-container">
                    <MenuIcon style={{ color: '#fff', cursor: 'pointer' }} onClick={handleHamburguerMenu} />
                    <span
                        style={{ color: '#fff', fontSize: '14px', cursor: 'pointer' }}
                        onClick={handleHamburguerMenu}
                    >
                        Menú
                    </span>
                </div>

                <nav className="desktop-navigation-container__subcontainer__menu-container">
                    <ul className="desktop-navigation-container__subcontainer__menu-container__list">
                        <li className="desktop-navigation-container__logo">
                            <Logo />
                        </li>
                        {navLinks
                            .filter(
                                (link) =>
                                    (link.logged ? user.logged : true) && (link.isAdmin ? user.isAdmin : true)
                            )
                            .filter((x) => x.hideInDesktop !== true)
                            .map((link, index) => (
                                <li
                                    key={index}
                                    className={`desktop-navigation-container__subcontainer__menu-container__list__menu-item dropdown`}
                                    style={{ zIndex: 999999 }}
                                >
                                    <button
                                        className="dropbtn desktop-navigation-container__subcontainer__menu-container__list__menu-item__submenu"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '5px',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <Link
                                                href={link.path}
                                                style={{ fontSize: '14px', whiteSpace: 'nowrap' }}
                                            >
                                                {link.title}
                                            </Link>
                                            {link.subMenu && (
                                                <ArrowDropDownIcon
                                                    style={{
                                                        position: 'relative',
                                                        left: '5px',
                                                        bottom: '2px',
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </button>
                                    {link.subMenu && renderSubMenu(link)}
                                </li>
                            ))}
                    </ul>
                </nav>
                <div
                    className="icon-partidos-drawer-container desktop-navigation-container__calendar"
                    onClick={handlePartidosMenu}
                >
                    <CalendarIcon iconWidth={30} iconHeight={30} />
                    <p className="icon-partidos-drawer-text">Partidos</p>
                </div>
            </div>
        </section>
    );
};

export default DesktopNavigationComponent;
