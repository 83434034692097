import { getScoreColor } from '@/helpers/players-helper';

const FixtureScore = ({ score, isAvailable, week, width, height, styles, isTransparent, pstyles }) => {
    const DEFAULT_WIDTH = '24px';
    const DEFAULT_HEIGHT = '24px';

    const background =
        score || score === 0 ? getScoreColor(score, isAvailable, isTransparent) : 'transparent';

    const scoreText = isAvailable && background !== 'transparent' ? score : '-';

    return (
        <div className="fixture-score-container">
            <div
                className="fixture-score-container__color"
                style={{
                    background: background,
                    width: width ? width : DEFAULT_WIDTH,
                    height: height ? height : DEFAULT_HEIGHT,
                    fontSize: '12px',
                    color: '#fff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '4px',
                    ...styles,
                }}
            >
                <div
                    style={{
                        margin: 0,
                        padding: 0,
                        ...pstyles,
                    }}
                >
                    {scoreText}
                </div>
            </div>
            <div className="fixture-score-container" style={{ fontSize: '12px' }}>
                <span>{week ? 'J' + week : null}</span>
            </div>
        </div>
    );
};

export default FixtureScore;
