import { Fragment, useContext, useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
    Drawer,
    Box,
    Typography,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    useMediaQuery,
    useTheme,
} from '@mui/material';

import axiosApiInstance from '@/api/shared/axios-instance';
import { REDUCERS } from '@/constants/reducers';
import { Context } from '@/context';
import { translate } from '@/data/fixture-transalations';
import { getTimeShortWithYear } from '@/helpers/datetime-helper';
import { getMasterPhotoById, getTeamImageById } from '@/helpers/images-helper';
import { getCompetitionImage } from '@/helpers/leagues-helper';
import { toPriceInEuros } from '@/helpers/prices-helper';
import URL_FACTORY from '@/helpers/url-helper';

import CustomImageComponent from '../custom/custom-image';
import FixtureScore from '../fixture-score';
import PlayerFullCard from '../player-full-card';

const CalendarActionsPlayerComponent = () => {
    const [open, setOpen] = useState(false);
    const { state, dispatch } = useContext(Context);
    const { playerCalendar } = state;

    const [data, setData] = useState(null);
    const [cache, setCache] = useState([]); // Estado para la caché
    const [anchor] = useState('right');

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const handleClose = () => {
        setOpen(false);
        setData(null);

        dispatch({
            type: REDUCERS.SHOW_PLAYER_CALENDAR,
            payload: null,
        });
    };

    useEffect(() => {
        if (playerCalendar > 0) {
            // Verificamos si el resultado está en la caché
            const isCached = cache.find((x) => x.playerCalendarId === playerCalendar);

            if (isCached) {
                setData(isCached.data);
                setOpen(true);
                return;
            }

            const payload = {
                playerId: playerCalendar,
            };

            // Si no está en la caché, hacemos la solicitud
            axiosApiInstance.post(URL_FACTORY.GetPlayerCalendar, payload).then((response) => {
                if (response?.data) {
                    setData(response.data);
                    setOpen(true);

                    // Agregamos el resultado a la caché
                    setCache((prevCache) => [
                        ...prevCache,
                        {
                            playerCalendarId: playerCalendar,
                            data: response.data,
                        },
                    ]);
                }
            });
        }
    }, [playerCalendar, cache]);

    const stylePadding = {
        padding: '12px 8px',
        textAlign: 'center',
        fontSize: '14px',
        color: '#000',
    };

    return (
        <Fragment>
            {data && (
                <Drawer
                    anchor={anchor}
                    open={open}
                    onClose={handleClose}
                    className="master-player-modal"
                    transitionDuration={300}
                >
                    {/* Encabezado */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            bgcolor: '#f9f9f9',
                            borderBottom: '1px solid #ddd',
                            padding: isMobile ? '8px' : '16px',
                            position: 'sticky',
                            top: 0,
                            zIndex: 9999,
                            height: '40px',
                        }}
                    >
                        <Typography variant="h6" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                            Calendario del jugador
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    {/* Contenido Principal */}
                    <Box sx={{ textAlign: 'center', display: 'flex' }} mb={1}>
                        <PlayerFullCard
                            playerImage={getMasterPhotoById(data?.id, 120)}
                            name={data?.name}
                            width={80}
                            height={80}
                            teamImage={getTeamImageById(data?.teamId)}
                            status={data?.status}
                            statusLevel={data?.statusLevel}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '16px',
                                justifyContent: 'space-between',
                                width: '100%',
                                paddingRight: '16px',
                            }}
                        >
                            <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="body2" fontWeight={'bold'}>
                                    {data.name}
                                </Typography>
                                <Typography variant="body2">{toPriceInEuros(data.marketValue)}</Typography>
                                <Typography
                                    variant="body2"
                                    color={data.subida > 0 ? 'success.main' : 'error.main'}
                                >
                                    {toPriceInEuros(data.subida)}
                                </Typography>
                            </Box>
                            <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="body2" color="text.secondary">
                                    Puntos: <strong>{data.points}</strong>
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Media: <strong>{data.averagePoints?.toFixed(2)}</strong>
                                </Typography>
                                <Box display={'flex'} gap="4px">
                                    {data.fitness?.map((f) => (
                                        <FixtureScore key={f} score={f} isAvailable={true} />
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    {/* Tabla de Partidos */}
                    <Box sx={{ overflow: 'auto', flex: 1 }}>
                        <Table sx={{ fontSize: '14px' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={stylePadding} align="center" width={'10%'}>
                                        Día
                                    </TableCell>
                                    <TableCell sx={stylePadding} align="center" width={'45%'}>
                                        Partido
                                    </TableCell>
                                    <TableCell sx={stylePadding} align="center" width={'45%'}>
                                        Fecha
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.fixtures?.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center" sx={stylePadding} width={'10%'}>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                padding: '8px 8px',
                                                textAlign: 'center',
                                                fontSize: '14px',
                                                color: '#000',
                                            }}
                                            width={'45%'}
                                        >
                                            {item?.fixture?.homeTeamId ? (
                                                <Box
                                                    display={'flex'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    gap="24px"
                                                >
                                                    <Box display={'flex'} gap="4px">
                                                        <CustomImageComponent
                                                            src={getCompetitionImage(
                                                                item?.fixture?.leagueId,
                                                                30
                                                            )}
                                                            alt={'competición'}
                                                            width={20}
                                                            height={20}
                                                        />
                                                        <Typography variant="body2" fontSize={'14px'}>
                                                            {translate(item?.fixture?.round, true)}
                                                        </Typography>
                                                    </Box>
                                                    <Box display={'flex'} gap="4px">
                                                        <CustomImageComponent
                                                            src={getTeamImageById(
                                                                item?.fixture?.homeTeamId,
                                                                30
                                                            )}
                                                            alt={item?.fixture?.homeTeamName}
                                                            width={20}
                                                            height={20}
                                                        />
                                                        <Typography variant="body2" fontSize={'14px'}>
                                                            vs
                                                        </Typography>
                                                        <CustomImageComponent
                                                            src={getTeamImageById(
                                                                item?.fixture?.awayTeamId,
                                                                30
                                                            )}
                                                            alt={item?.fixture?.awayTeamName}
                                                            width={20}
                                                            height={20}
                                                        />
                                                    </Box>
                                                </Box>
                                            ) : null}
                                        </TableCell>
                                        <TableCell align="center" sx={stylePadding} width={'45%'}>
                                            {getTimeShortWithYear(item.fixture?.date)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Drawer>
            )}
        </Fragment>
    );
};

export default CalendarActionsPlayerComponent;
