import { REDUCERS } from '@/constants/reducers';

export function fixtures(state, action) {
    switch (action.type) {
        case REDUCERS.UPDATE_FIXTURES_ROUND:
            let fixtures = { ...state.fixtures, ...action.payload };
            return { ...state, fixtures };
        case REDUCERS.UPDATE_FECHAS_CLAUSULABLES:
            const newState = {
                ...state,
                fechasClausulables: action.payload,
            };
            return newState;
        default:
            return state;
    }
}
