import React, { useContext, useEffect } from 'react';

import { Button, useMediaQuery } from '@mui/material';
import { useRouter } from 'next/router';

import HomeFlashList from '@/components/home/home-flash-list';
import { REDUCERS } from '@/constants/reducers';
import { Rutas } from '@/constants/rutas';
import { Context } from '@/context';

import Placement1Component from '../ads-components/placements/placement1';
import Placement6Component from '../ads-components/placements/placement6';
import NoticiasListComponent from '../noticias/noticia-list-component';
import NewsIcon from '../shared/icons/news-icon';
import LazyLoadWrapper from '../shared/wrappers/lazy-load-wrapper';

import HomeActivities from './home-activities';
import HomeFixtures from './home-fixtures';

const HomePageComponent = ({ actividades, noticiasFlash, noticias }) => {
    const { state, dispatch } = useContext(Context);
    const router = useRouter();
    const { activities } = state;
    const isLargeDesktop = useMediaQuery('(min-width:1400px)');
    const isMedium = useMediaQuery('(min-width:576px)');

    useEffect(() => {
        if (actividades?.length > 0) {
            dispatch({
                type: REDUCERS.UPDATE_ACTIVITIES,
                payload: {
                    ...activities,
                    activities: [...activities.activities, ...actividades],
                },
            });
        }
    }, [actividades, noticiasFlash, noticias]);

    return (
        <div>
            <div className="row home-container__main-row mb-25">
                <div className="col-12">
                    <h1>Analítica Fantasy: Noticias y Estadísticas de Fantasy Fútbol</h1>
                </div>
                <div className="col-12 col-lg-8">
                    <NoticiasListComponent
                        numberOfArticles={1}
                        start={0}
                        noticias={noticias}
                        col={12}
                        postHeight={isLargeDesktop ? 488 : isMedium ? 440 : null}
                    />

                    <Placement1Component />

                    <NoticiasListComponent numberOfArticles={6} start={1} noticias={noticias} />

                    <Placement6Component />
                </div>
                <div className="col-12 col-lg-4" id="noticias-flash-container">
                    <HomeActivities
                        customActivities={actividades}
                        isPage={false}
                        showHeader={true}
                        showFilters={false}
                        col={6}
                        customTake={15}
                    />

                    <HomeFlashList
                        take={5}
                        header="Últimas noticias flash de Fantasy Fútbol: lesiones, análisis y recomendaciones"
                        hideHeader={false}
                        hideSearch={true}
                        noticiasFlash={noticiasFlash}
                    />

                    <LazyLoadWrapper>
                        <HomeFixtures />
                    </LazyLoadWrapper>
                </div>

                <div className="col-12">
                    <NoticiasListComponent numberOfArticles={-1} start={7} noticias={noticias} col={4} />
                </div>

                <Button
                    color="primary"
                    aria-haspopup="true"
                    variant="outlined"
                    disableElevation
                    onClick={() => router.push(Rutas.NOTICIAS)} // Función para cargar más artículos
                    style={{ width: '100%' }}
                >
                    <NewsIcon />
                    <span style={{ marginLeft: '4px' }}>Ver todas las noticias</span>
                </Button>
                <section className="text-help-content">
                    <h2>
                        ¡Bienvenido a <strong>Analítica Fantasy</strong>!
                    </h2>
                    <p>
                        Aquí encontrarás todo lo que necesitas para <strong>dominar tu liga Fantasy</strong>.
                    </p>
                    <ul>
                        <li>
                            <strong>Alineaciones probables</strong> actualizadas antes de cada jornada.
                        </li>
                        <li>
                            Información clave sobre el <strong>mercado de fichajes</strong> y{' '}
                            <strong>subidas de valor</strong>.
                        </li>
                        <li>
                            <strong>Análisis de plantilla</strong> para mejorar tus decisiones.
                        </li>
                        <li>
                            <strong>Estadísticas detalladas</strong> y noticias en tiempo real.
                        </li>
                    </ul>
                    <p>
                        Explora nuestras secciones, descubre jugadores <strong>lesionados</strong> o{' '}
                        <strong>sancionados</strong> {''}y optimiza tu equipo{' '}
                        <strong>jornada tras jornada</strong>.
                    </p>
                    <p>
                        ¡Prepárate para <strong>ganar</strong> con <strong>Analítica Fantasy</strong>!
                    </p>
                </section>
            </div>
        </div>
    );
};

export default HomePageComponent;
