import { useContext } from 'react';

import { Modal } from '@mui/material';

import { REDUCERS } from '@/constants/reducers';
import { Context } from '@/context';

import ActionsPlayerContent from './actions-player-content';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    paddingBottom: '16px',
};

const ModalActionsPlayer = ({ open, playerId, setOpen }) => {
    const { dispatch } = useContext(Context);
    return (
        <div className="master-actions-players__modal">
            <Modal
                keepMounted
                open={open}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
                onClose={() => {
                    dispatch({
                        type: REDUCERS.SHOW_MASTER_ACCIONES_PLAYER_ID,
                        payload: null,
                    });
                    setOpen(false);
                }}
            >
                <ActionsPlayerContent playerId={playerId} style={style} setOpen={setOpen} />
            </Modal>
        </div>
    );
};

export default ModalActionsPlayer;
