import Link from 'next/link';

import { Rutas } from '@/constants/rutas';

import CustomImageComponent from './custom/custom-image';

const Logo = ({ styles, width, disableClick }) => {
    return (
        <Link href={Rutas.INICIO}>
            <CustomImageComponent
                className="image-logo__image"
                src={`${process.env.NEXT_PUBLIC_IMAGES}/analiticafantasy.png?width=${
                    width ? width * 1.5 : 105
                }`}
                alt="logo"
                width={width ?? 70}
                height={width ?? 70}
                priority={true}
                onClick={() => {
                    if (disableClick) {
                        return;
                    }
                }}
                style={styles}
            />
        </Link>
    );
};

export default Logo;
