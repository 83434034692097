import { useEffect, useState, useRef } from 'react';

import MoneyAd, { MoneyAdType } from '../themoneytizer/money-ad';

const PlacementTopComponent = () => {
    const adContainerRef = useRef(null);
    const [adState, setAdState] = useState('hb'); // 'hb', 'videoo'

    useEffect(() => {
        const timer = setTimeout(() => {
            const adContainer = adContainerRef.current;

            if (adContainer) {
                const adFrame = adContainer.querySelector('iframe');
                const adLoaded =
                    adFrame &&
                    adFrame.contentWindow &&
                    adFrame.contentWindow.document.body.childElementCount > 0;

                // Si no se cargó nada, cambiar a Videoo
                if (!adLoaded) {
                    setAdState('videoo');
                }
            } else {
                setAdState('videoo'); // Cambiar a Videoo si no hay contenedor
            }
        }, 10000); // Verificar después de 10 segundos

        return () => clearTimeout(timer);
    }, []);

    return adState === 'hb' ? (
        <div className="hb-ad-inarticle d-flex-center placement-ad-template">
            <div className="hb-ad-inner">
                <div className="hbagency_cls" id="hbagency_space_177071" ref={adContainerRef}></div>
            </div>
        </div>
    ) : (
        <MoneyAd type={MoneyAdType.BILLBOARD} />
    );
};

export default PlacementTopComponent;
