import axiosApiInstance from '@/api/shared/axios-instance';
import HomePageComponent from '@/components/home';
import Seo from '@/components/shared/custom/seo-component';
import { IsrRevalidateTime } from '@/constants/cache-state-constants';
import { Pages } from '@/constants/rutas';
import URL_FACTORY from '@/helpers/url-helper';

const HomePage = ({ noticias, noticiasFlash, actividades }) => {
    return (
        <div className="home-container">
            <Seo pageId={Pages.INICIO} />
            <div className="container">
                <HomePageComponent
                    noticias={noticias}
                    noticiasFlash={noticiasFlash}
                    actividades={actividades}
                />
            </div>
        </div>
    );
};

export const getStaticProps = async () => {
    try {
        // Definir la cantidad de datos a obtener
        const noticiasLimit = 16; // Cantidad de noticias
        const noticiasFlashLimit = 5; // Cantidad de noticias flash
        const activitiesLimit = 5; // Cantidad de actividades

        const [noticiasResponse, noticiasFlashResponse, activitiesResponse] = await Promise.all([
            axiosApiInstance.post(URL_FACTORY.URL_GET_PUBLISHED_ARTICLES, {
                skip: 0,
                take: noticiasLimit,
            }),
            axiosApiInstance.post(URL_FACTORY.GetNoticiasFlash, {
                skip: 0,
                take: noticiasFlashLimit,
            }),
            axiosApiInstance.post(URL_FACTORY.GetActivities, {
                take: activitiesLimit,
            }),
        ]);

        // Retornamos los datos obtenidos como props para la página
        return {
            props: {
                noticias: noticiasResponse.data.articles || [],
                noticiasFlash: noticiasFlashResponse.data.noticias || [],
                actividades: activitiesResponse.data.activities || [],
            },
            revalidate: IsrRevalidateTime.HOME_PAGE, // ISR: regenerar la página cada 60 segundos
        };
    } catch (error) {
        // Retornar datos vacíos en caso de error para evitar que la página falle
        return {
            props: {
                noticias: [],
                noticiasFlash: [],
                activities: [],
            },
            revalidate: IsrRevalidateTime.HOME_PAGE, // Revalidar la página aunque ocurra un error
        };
    }
};

export default HomePage;
