import { Fragment, useContext, useEffect, useState } from 'react';

import { Drawer } from '@mui/material';

import axiosApiInstance from '@/api/shared/axios-instance';
import { REDUCERS } from '@/constants/reducers';
import { Context } from '@/context';
import { EVENTS_ACTIONS, EVENTS_CATEGORIES, EVENTS_LABELS, logEventTracker } from '@/helpers/events-helper';
import URL_FACTORY from '@/helpers/url-helper';

import MasterPlayerCardComponent from './master-player-card';
import MasterPlayerHeaderComponent from './master-player-header';
import MasterPlayerTabsComponent from './master-player-tabs';

const MasterPlayerModalComponent = () => {
    const [open, setOpen] = useState(false);
    const [player, setPlayer] = useState(null);
    const { state, dispatch } = useContext(Context);
    const { playerProfile } = state;
    const [cache, setCache] = useState([]);
    const [anchor] = useState('right');

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (playerProfile?.fantasyId > 0 || playerProfile?.playerId > 0) {
            const isCached = cache?.find(
                (x) =>
                    (x.fantasyId == playerProfile?.fantasyId && x.fantasyId > 0) ||
                    (x.playerId == playerProfile?.playerId && x.playerId > 0)
            );

            if (isCached) {
                setOpen(true);
                setPlayer(isCached.data);
                logEventTracker(EVENTS_ACTIONS.MASTER_PLAYER, EVENTS_CATEGORIES.OPEN, EVENTS_LABELS.CACHE);
                return;
            }

            const url = URL_FACTORY.GetPlayerProfile;

            const payload = {
                fantasyId: playerProfile?.fantasyId,
                playerId: playerProfile?.playerId,
            };

            dispatch({ type: REDUCERS.SHOW_SPINNER });

            logEventTracker(EVENTS_ACTIONS.MASTER_PLAYER, EVENTS_CATEGORIES.OPEN, EVENTS_LABELS.API);

            axiosApiInstance
                .post(url, payload)
                .then((response) => {
                    if (response?.data?.information) {
                        setOpen(true);
                        setPlayer(response?.data);

                        // Add response to cache
                        setCache((prevCache) => [
                            ...prevCache,
                            {
                                fantasyId: response?.data.fantasyPlayer?.information?.id,
                                playerId: response?.data.information?.id,
                                data: response?.data,
                            },
                        ]);
                    }
                })
                .finally(() => {
                    dispatch({ type: REDUCERS.HIDE_SPINNER });
                });
        } else {
            setOpen(false);
        }
    }, [playerProfile]);

    const renderModal = () => {
        if (!player || !open) return null;
        return (
            <Drawer
                anchor={anchor}
                open={open}
                onClose={() => handleClose(anchor, false)}
                className="master-player-modal"
                transitionDuration={300}
            >
                <MasterPlayerHeaderComponent handleClose={handleClose} player={player} />

                <MasterPlayerCardComponent player={player} isDrawer={true} />

                <MasterPlayerTabsComponent
                    player={player}
                    selectedIndex={playerProfile?.selectedIndex ?? 1}
                />
            </Drawer>
        );
    };

    return <Fragment>{renderModal()}</Fragment>;
};

export default MasterPlayerModalComponent;
