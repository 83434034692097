import { useContext, useState, useEffect } from 'react';

import { Box } from '@mui/material';

import axiosApiInstance from '@/api/shared/axios-instance';
import { REDUCERS } from '@/constants/reducers';
import { Rutas } from '@/constants/rutas';
import { Context } from '@/context';
import URL_FACTORY from '@/helpers/url-helper';

import CustomAlertActividades from './custom-alert-actividad';

const ActividadBanner = () => {
    const { state, dispatch } = useContext(Context);
    const { activities } = state;
    const [activeStep, setActiveStep] = useState(0);
    const [localActivities, setLocalActivities] = useState([]);

    const maxSteps = activities?.activities?.length ?? 0;

    const getActivitiesData = () => {
        axiosApiInstance
            .post(URL_FACTORY.GetActivities, { skip: activities.skip, take: activities.take })
            .then((response) => {
                setLocalActivities(response.data.activities);
                dispatch({
                    type: REDUCERS.UPDATE_ACTIVITIES,
                    payload: {
                        ...activities,
                        activities: [...response.data.activities],
                        shouldRefresh: false,
                    },
                });
            });
    };

    useEffect(() => {
        if (activities.activities.length > 0) {
            setLocalActivities(activities.activities);
        } else {
            getActivitiesData();
        }
    }, [activities]);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
        }, 100000); // Cambiar cada 10 segundos

        return () => {
            clearInterval(interval);
        };
    }, [maxSteps]);

    return (
        <Box className="actividad-banner-container" style={{ backgroundColor: '#e8f4fd' }}>
            {maxSteps > 0 ? (
                <div>
                    {localActivities?.map((activity, index) => (
                        <div
                            key={index}
                            style={{ display: index === activeStep ? 'flex' : 'none', alignItems: 'center' }}
                        >
                            <CustomAlertActividades text={activity.title} route={Rutas.ACTIVIDADES} />
                        </div>
                    ))}
                </div>
            ) : null}
        </Box>
    );
};

export default ActividadBanner;
