import { useContext, useEffect, useState } from 'react';

import MasterPlayerModalComponent from '@/components/master-player-modal';
import FantasyHistoricModalComponent from '@/components/master-player-modal/fantasy-historic-modal';
import { Context } from '@/context';

import ShowMarketProjectionComponent from '../show-market-projection';

import CalendarActionsPlayerComponent from './calendar-actions-player';
import ModalActionsPlayer from './modal-actions-player';

const MasterActionsPlayersComponent = () => {
    // leer del context
    const { state } = useContext(Context);
    // leer del state
    const { masterAccionesPlayerId } = state;

    // crear un estado local
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (masterAccionesPlayerId > 0) {
            setOpen(true);
        }
    }, [masterAccionesPlayerId]);

    return (
        <div className="master-actions-players">
            {open && <ModalActionsPlayer playerId={masterAccionesPlayerId} open={open} setOpen={setOpen} />}

            <CalendarActionsPlayerComponent />
            <MasterPlayerModalComponent />
            <FantasyHistoricModalComponent />
            <ShowMarketProjectionComponent />
        </div>
    );
};

export default MasterActionsPlayersComponent;
