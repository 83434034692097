import { Typography } from '@mui/material';
import Link from 'next/link';

import AlertInfoIcon from './icons/custom-alert/alert-info-icon';

const CustomAlertActividades = ({ text, route }) => {
    return (
        <Link
            href={route}
            style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '4px',
                textDecoration: 'underline',
                gap: "4px"
            }}>
            <AlertInfoIcon style={{ width: '20px' }} />

            <Typography
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '100',
                    fontSize: '14px',
                }}
            >
                {text}
            </Typography>
        </Link>
    );
};

export default CustomAlertActividades;
