import { REDUCERS } from '@/constants/reducers';
import { Rutas } from '@/constants/rutas';
import { SITE_USER_COOKIE } from '@/constants/site';
import { getFromLocalStorage, removeFromLocalStorage, addToLocalStorage } from '@/helpers/cookies';

import { initialState } from '../initial-state';

export function user(state, action) {
    switch (action.type) {
        case REDUCERS.LOGGED_OUT_IN:
            removeFromLocalStorage(SITE_USER_COOKIE);
            const userData = JSON.stringify(action.payload);
            addToLocalStorage(SITE_USER_COOKIE, userData, 30);
            window.location.reload();
            return { ...state, user: action.payload };
        case REDUCERS.LOGGED_IN_USER:
            const jsonData = JSON.stringify(action.payload);
            addToLocalStorage(SITE_USER_COOKIE, jsonData, 30);
            if (action.payload?.prevUrl?.length > 2) {
                window.location.href = action.payload?.prevUrl;
            } else {
                window.location.href = Rutas.INICIO;
            }
            return { ...state, user: action.payload };
        case REDUCERS.LOGGED_OUT_USER:
            removeFromLocalStorage(SITE_USER_COOKIE);
            window.location.href = Rutas.INICIO;
            return { ...state, user: initialState };
        case REDUCERS.GET_DATA_FROM_COOKIES:
            const dataFromCookies = getFromLocalStorage(SITE_USER_COOKIE);
            const data = JSON.parse(dataFromCookies);
            const newState = data || initialState.user;
            return {
                ...state,
                user: {
                    ...state.user,
                    ...newState,
                },
            };
        case REDUCERS.UPDATE_USER:
            const { user } = state;
            const isNotLogin =
                action.payload.currentUrl !== Rutas.INICIA_SESION &&
                action.payload.currentUrl !== Rutas.REGISTRATE;
            const nuevoEstado = {
                ...state,
                user: {
                    ...user,
                    ...action.payload,
                },
            };

            if (action.payload.currentUrl) {
                nuevoEstado?.user?.urls?.push(action.payload.currentUrl);
            }

            if (isNotLogin) {
                nuevoEstado.prevUrl = action.payload.currentUrl;
            }

            return nuevoEstado;
        case REDUCERS.UPDATE_SUBSCRIPTION:
            addToLocalStorage(SITE_USER_COOKIE, JSON.stringify(action.payload), 30);
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
}
