import LocalPlayIcon from '@mui/icons-material/LocalPlay';

import AlineacionesActivityIcon from '../home/icons/alineaciones-activity-icon';

import ActivityEventsIcon from './icons/activity-icon';
import AdminIcon from './icons/admin-icon';
import AdminJugadoresIcon from './icons/admin-jugadores-icon';
import AdminSyncIcon from './icons/admin-sync-icon';
import AdministradorImagenesIcon from './icons/administrador-imagenes-icon';
import AdministradorParteMedicoIcon from './icons/administrador-parte-medico-icon';
import AlineacionesManagersIcon from './icons/alineaciones-manager-icon';
import AnalizaTuPlantillaIcon from './icons/analiza-tu-plantilla-icon';
import ApercibidosStatusIcon from './icons/apercibidos-status-icon';
import AppIcon from './icons/app-icon';
import BiwengerIcon from './icons/biwenger-icon';
import BuscadorJugadoresIcon from './icons/buscador-jugadores-icon';
import CalendarioFantasyAdminIcon from './icons/calendario-fantasy-admin';
import ClasificacionNavIcon from './icons/clasificacion-nav-icon';
import ColaboradoresIcon from './icons/colaboradores-icon';
import ComparadorFantasyIcon from './icons/comparador-fantasy-icon';
import ConfigurationIcon from './icons/configuration-icon';
import ConvocatoriasIcon from './icons/convocatorias-icon';
import CrearAlineacionesIcon from './icons/crear-alineaciones-icon';
import FantasyLaLigaEstadisticasIcon from './icons/fantasy-la-liga-estadisticas-icon';
import FantasyLaLigaIcon from './icons/fantasy-la-liga-icon';
import FantasyLaLigaJugadoresIcon from './icons/fantasy-la-liga-jugadores-icon';
import FullCalendarIcon from './icons/full-calendar';
import InfoIcon from './icons/info-icon';
import InfoPlayerIcon from './icons/info-player-icon';
import LanzadoresPenaltisIcon from './icons/lanzadores-penaltis-icon';
import LesionadosIcon from './icons/lesionados-icon';
import LideresEstadisticasIcon from './icons/lideres-estadisticas-icon';
import MercadoFichajesLive from './icons/mercado-fichajes-live';
import MercadoIcon from './icons/mercado-icon';
import MisPlantillasIcon from './icons/mis-plantillas-icon';
import NewsIcon from './icons/news-icon';
import NoticiasFlashIcon from './icons/noticias-flash-icon';
import NovedadesIcon from './icons/novedades';
import OraculoIcon from './icons/oraculo-icon';
import PlantillasIcon from './icons/plantillas-icon';
import PredictingManagerIcon from './icons/predicting-manager-icon';
import PredictingManagerRulesIcon from './icons/predicting-manager-rules-icon';
import PredictingRankingIcon from './icons/predicting-ranking-icon';
import PublicTierlistIcon from './icons/public-tierlist-icon';
import PujaIdealIcon from './icons/puja-idea-icon';
import PuntuacionesFantasyIcon from './icons/puntuaciones-fantasy-icon';
import QuinielaAnaliticaIcon from './icons/quiniela-analitica-icon';
import RachasIcon from './icons/rachas-icon';
import ChollosIcon from './icons/recommendation-icons/chollos-icon';
import SalonDeLaFamaIcon from './icons/salon-de-la-fama';
import SancionadosIcon from './icons/sancionados-icon';
import DudaStatusIcon from './icons/status-icons/duda-status-icon';
import PremiumIcon from './icons/subscriptions/premium';
import VirusFifaIcon from './icons/virus-fifa-icon';
import VoteIcon from './icons/vote-icon';

const getIcon = (id, width, height) => {
    const iconWidth = width ? width : 30;
    const iconHeight = height ? height : 30;

    switch (id) {
        case 'inicio':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width={'25'} height={'25'} viewBox="0 0 48 48">
                    <path fill="#E8EAF6" d="M42 39H6V23L24 6l18 17z" />
                    <path fill="#C5CAE9" d="m39 21l-5-5V9h5zM6 39h36v5H6z" />
                    <path fill="#B71C1C" d="M24 4.3L4 22.9l2 2.2L24 8.4l18 16.7l2-2.2z" />
                    <path fill="#D84315" d="M18 28h12v16H18z" />
                    <path fill="#01579B" d="M21 17h6v6h-6z" />
                    <path
                        fill="#FF8A65"
                        d="M27.5 35.5c-.3 0-.5.2-.5.5v2c0 .3.2.5.5.5s.5-.2.5-.5v-2c0-.3-.2-.5-.5-.5"
                    />
                </svg>
            );
        case 'administrador-once-tipo':
            return <AlineacionesActivityIcon iconWidth={iconWidth} iconHeight={iconHeight} color={'#fff'} />;
        case 'players-status':
            return <InfoPlayerIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'biwenger':
            return <BiwengerIcon width={iconWidth} height={iconHeight} />;
        case 'admin':
            return <AdminIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'admin-sync-data':
            return <AdminSyncIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'admin-noticias-flash':
        case 'noticias-flash':
            return <NoticiasFlashIcon />;
        case 'administrador-jugadores':
            return <AdminJugadoresIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'noticias':
            return <NewsIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'mercado':
        case 'mercado-biwenger':
            return <MercadoIcon />;
        case 'estadisticas-biwenger':
            return <FantasyLaLigaEstadisticasIcon />;
        case 'plantillas':
            return <PlantillasIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'mis-plantillas':
            return <MisPlantillasIcon />;
        case 'analiza-tu-plantilla':
            return <AnalizaTuPlantillaIcon iconWidth={'25'} iconHeight={'25'} />;
        case 'lideres-estadisticas':
            return <LideresEstadisticasIcon iconHeight={'25'} iconWidth={'25'} />;
        case 'alineaciones-probables':
            return <AlineacionesActivityIcon />;
        case 'colaboradores':
            return <ColaboradoresIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'lanzadores-penaltis':
            return <LanzadoresPenaltisIcon width={iconWidth} height={iconHeight} />;
        case 'premium':
            return <PremiumIcon width={iconWidth} height={iconHeight} />;
        case 'administrador-parte-medico':
            return <AdministradorParteMedicoIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'administrador-imagenes':
            return <AdministradorImagenesIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'fantasy-la-liga':
            return <FantasyLaLigaIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'fantasy-la-liga-estadisticas':
            return <FantasyLaLigaEstadisticasIcon />;
        case 'administrador-calendario-admin':
            return <CalendarioFantasyAdminIcon />;
        case 'fantasy-la-liga-estadisticas-jugadores':
            return <FantasyLaLigaJugadoresIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'fantasy-la-liga-comparador':
            return <ComparadorFantasyIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'oraculo-fantasy':
            return <OraculoIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'puntuaciones-jornada':
            return <PuntuacionesFantasyIcon width={iconWidth} height={iconHeight} />;
        case 'fantasy-la-liga-rachas':
            return <RachasIcon color={'#fff'} />;
        case 'administrador-de-partidos':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 64 64">
                    <path
                        fill="#fbb042"
                        d="M61.778 53.861a4.2 4.2 0 0 1 .132 5.938l-.268.275c-1.602 1.676-4.768 1.189-6.442-.413L13.467 19.7l6.069-6.345z"
                    />
                    <path
                        fill="#e8a042"
                        d="M22.03 23c-1.564 2.148-.937 3.964.555 5.391l33.12 31.744c1.49 1.428 3.332 1.972 5.41.316z"
                    />
                    <path
                        fill="#627c7f"
                        d="M12.133 22.89c.794-2.237 14.937-15.37 17.608-15.948c2.67-.578 12.725-.672 13.422 1.587c.496 1.599-.333 1.771-.721 1.804c-.5.042-8.227 1.733-9.525 2.743s-3.104 7.649-2.743 8.515s-.215 2.092-.36 2.381s-5.99 5.268-6.423 5.268s-4.112-1.226-4.834.434s-7.218-4.547-6.424-6.784"
                    />
                    <path
                        fill="#7c9c9f"
                        d="M18.57 39.05c-1.3 1.354-3.23 1.623-4.306.593L3.058 28.92c-1.079-1.031-.625-2.708.673-4.065l3.532-3.691c1.299-1.359 3.129-2.067 4.207-1.038l11.207 10.725c1.077 1.029.723 3.15-.577 4.511z"
                    />
                </svg>
            );
        case 'configuraciones-generales':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 100 100">
                    <path
                        fill="#BDC3C7"
                        fillRule="evenodd"
                        d="M97.55 85.718L45.407 33.574c-4.588-4.587 3.054-15.538-5.729-24.32L23.664 0l-3.381 3.38l8.832 8.831c3.381 3.38.849 10.983-2.545 14.377c-3.367 3.367-10.977 5.906-14.357 2.525l-8.833-8.83L0 23.664l9.254 16.014c8.734 8.735 19.87 1.277 24.321 5.729l52.143 52.144A8.367 8.367 0 0 0 97.55 85.718m-3.381 8.451a3.585 3.585 0 1 1-5.07-5.07a3.585 3.585 0 0 1 5.07 5.07"
                        clipRule="evenodd"
                    />
                    <path
                        fill="#95A5A6"
                        d="M33.682 12.334L22.512 1.151L20.283 3.38l8.832 8.831c3.381 3.38.849 10.983-2.545 14.377c-3.367 3.367-10.977 5.906-14.357 2.525l-8.833-8.83l-1.975 1.975l11.177 11.19c1.524 1.525 3.914 2.332 6.911 2.332c4.492 0 9.453-1.824 12.063-4.437c4.417-4.42 6.311-14.822 2.126-19.009m62.064 75.615L45.775 37.972c-1.042-1.042-2.426-1.615-3.898-1.615s-2.857.574-3.898 1.615a5.52 5.52 0 0 0 0 7.798L87.95 95.746c1.041 1.042 2.426 1.615 3.898 1.615s2.857-.573 3.898-1.615a5.52 5.52 0 0 0 0-7.797m-1.577 6.22a3.585 3.585 0 1 1-5.07-5.07a3.585 3.585 0 0 1 5.07 5.07"
                    />
                    <path
                        fill="#ECF0F1"
                        fillRule="evenodd"
                        d="M80 14L93 4l7 7l-10 13h-5L55 54l-5-5l30-30z"
                        clipRule="evenodd"
                    />
                    <path
                        fill="#BDC3C7"
                        fillRule="evenodd"
                        d="M52.5 51.5L55 54l30-30h5l10-13l-3.5-3.5z"
                        clipRule="evenodd"
                    />
                    <path
                        fill="#D35400"
                        fillRule="evenodd"
                        d="m42.51 46.095l.854.845L5.768 84.161a5.93 5.93 0 0 0 0 8.447l5.119 5.068c2.356 2.332 5.17 3.326 7.526.994l38.603-38.216l.853.845c.942.933 2.471.933 3.413 0s.942-2.446 0-3.379L45.923 42.716c-.942-.933-2.471-.933-3.413 0s-.943 2.446 0 3.379"
                        clipRule="evenodd"
                    />
                    <path
                        fill="#E66612"
                        fillRule="evenodd"
                        d="M50.25 53.75L8.594 95.406l2.293 2.271c2.356 2.332 5.17 3.326 7.526.994l38.573-38.186z"
                        clipRule="evenodd"
                    />
                </svg>
            );
        case 'fantasy-la-liga-calendario':
            return <CalendarioFantasyAdminIcon />;
        case 'predicting-manager-lineups':
            return <CrearAlineacionesIcon />;
        case 'predicting-manager-ranking':
        case 'quiniela-clasificacion':
            return <PredictingRankingIcon />;
        case 'predicting-manager-alineaciones':
            return <AlineacionesManagersIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'predicting-manager-reglas':
        case 'quiniela-reglas-del-juego':
            return <PredictingManagerRulesIcon />;
        case 'predicting-manager':
            return <PredictingManagerIcon />;
        case 'puja-fantasy':
        case 'puja-fantasy-biwenger':
            return <PujaIdealIcon />;
        case 'quiniela-administrador':
            return <LocalPlayIcon style={{ width: iconWidth, height: iconHeight }} />;
        case 'quiniela':
            return <QuinielaAnaliticaIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'actividades':
            return <ActivityEventsIcon width={'25'} height={'25'} />;
        case 'quiniela-juego':
            return <VoteIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'jugadores-lesionados':
            return <LesionadosIcon iconWidth={'25'} iconHeight={'25'} />;
        case 'jugadores-sancionados':
            return <SancionadosIcon />;
        case 'jugadores-dudas':
            return <DudaStatusIcon iconWidth={'25'} iconHeight={'25'} />;
        case 'jugadores-apercibidos':
            return <ApercibidosStatusIcon />;
        case 'calendar-competitions':
            return <FullCalendarIcon color={'#fff'} iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'configuration':
        case 'configuration-twitch':
            return <ConfigurationIcon />;
        case 'administrador-convocatorias':
            return <ConvocatoriasIcon />;
        case 'virus-fifa':
            return <VirusFifaIcon />;
        case 'salon-de-la-fama':
            return <SalonDeLaFamaIcon />;
        case 'quedada-fantasy':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width={'25'} height={'25'} viewBox="0 0 48 48">
                    <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m14.28 22.2l4-2.2m0 0v16m6.127-1.35c1.104.926 2.297 1.35 4.975 1.35h.624a4 4 0 0 0 3.999-4h0a4 4 0 0 0-4-3.998m-5.6-6.666c1.107-.922 2.3-1.343 4.979-1.336l.622.004a4 4 0 0 1 3.999 3.999h0a4 4 0 0 1-4 3.999m-3.073 0h3.074"
                    />
                    <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
                        <rect width="35" height="36" x="6.5" y="7.5" rx="4" ry="4" />
                        <path d="M33.5 4.5v6m-19-6v6" />
                    </g>
                </svg>
            );
        case 'la-liga':
            return <InfoIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'clasification-competitions':
            return <ClasificacionNavIcon />;
        case 'chollos':
            return <ChollosIcon width={20} height={20} />;
        case 'mercado-de-fichajes-en-vivo':
            return <MercadoFichajesLive />;
        case 'cuentas-de-usuario':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={iconWidth}
                    height={iconHeight}
                    viewBox="0 0 24 24"
                >
                    <path
                        fill="#fff"
                        d="M12 11q.825 0 1.413-.588Q14 9.825 14 9t-.587-1.413Q12.825 7 12 7q-.825 0-1.412.587Q10 8.175 10 9q0 .825.588 1.412Q11.175 11 12 11Zm0 2q-1.65 0-2.825-1.175Q8 10.65 8 9q0-1.65 1.175-2.825Q10.35 5 12 5q1.65 0 2.825 1.175Q16 7.35 16 9q0 1.65-1.175 2.825Q13.65 13 12 13Zm0 11q-2.475 0-4.662-.938q-2.188-.937-3.825-2.574Q1.875 18.85.938 16.663Q0 14.475 0 12t.938-4.663q.937-2.187 2.575-3.825Q5.15 1.875 7.338.938Q9.525 0 12 0t4.663.938q2.187.937 3.825 2.574q1.637 1.638 2.574 3.825Q24 9.525 24 12t-.938 4.663q-.937 2.187-2.574 3.825q-1.638 1.637-3.825 2.574Q14.475 24 12 24Zm0-2q1.8 0 3.375-.575T18.25 19.8q-.825-.925-2.425-1.612q-1.6-.688-3.825-.688t-3.825.688q-1.6.687-2.425 1.612q1.3 1.05 2.875 1.625T12 22Zm-7.7-3.6q1.2-1.3 3.225-2.1q2.025-.8 4.475-.8q2.45 0 4.463.8q2.012.8 3.212 2.1q1.1-1.325 1.713-2.95Q22 13.825 22 12q0-2.075-.788-3.887q-.787-1.813-2.15-3.175q-1.362-1.363-3.175-2.151Q14.075 2 12 2q-2.05 0-3.875.787q-1.825.788-3.187 2.151Q3.575 6.3 2.788 8.113Q2 9.925 2 12q0 1.825.6 3.463q.6 1.637 1.7 2.937Z"
                    />
                </svg>
            );
        case 'administrador-buscador-jugadores':
            return <BuscadorJugadoresIcon />;
        case 'app':
            return <AppIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'tier-list':
            return (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={iconWidth}
                    height={iconHeight}
                    viewBox="0 0 512 512"
                >
                    <path
                        fill="#FFD469"
                        d="M450.812 462.658H74.759a8.802 8.802 0 0 1-8.802-8.802V77.802A8.802 8.802 0 0 1 74.759 69h376.215a8.64 8.64 0 0 1 8.64 8.64v376.215a8.802 8.802 0 0 1-8.802 8.803"
                    />
                    <path
                        fill="#597B91"
                        d="M393.375 168.403H126.007c-6.613 0-11.974-5.36-11.974-11.973s5.361-11.973 11.974-11.973h267.368c6.612 0 11.974 5.36 11.974 11.973s-5.362 11.973-11.974 11.973m-53.059 66.401c0-6.613-5.361-11.974-11.974-11.974H126.007c-6.613 0-11.974 5.361-11.974 11.974s5.361 11.974 11.974 11.974h202.335c6.613-.001 11.974-5.361 11.974-11.974m-85.479 78.374c0-6.612-5.361-11.974-11.974-11.974H126.007c-6.613 0-11.974 5.361-11.974 11.974s5.361 11.974 11.974 11.974h116.856c6.613-.001 11.974-5.362 11.974-11.974m101.165 78.374c0-6.612-5.361-11.974-11.974-11.974H126.007c-6.613 0-11.974 5.361-11.974 11.974s5.361 11.974 11.974 11.974h218.021c6.613-.001 11.974-5.362 11.974-11.974m40.334-78.374c0-6.612-5.361-11.974-11.974-11.974h-80.668c-6.612 0-11.974 5.361-11.974 11.974s5.361 11.974 11.974 11.974h80.668c6.613-.001 11.974-5.362 11.974-11.974"
                    />
                </svg>
            );
        case 'public-tierlist':
            return <PublicTierlistIcon iconWidth={iconWidth} iconHeight={iconHeight} />;
        case 'novedades':
            return <NovedadesIcon />;
    }
};

export { getIcon };
