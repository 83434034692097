export const PLAYER_STATUS = {
    DOUBTFUL: 'doubt',
    INJURED: 'injured',
    OK: 'ok',
    SANCTIONED: 'sanctioned',
    APERCIBIDO: 'apercibido',
    CONVOCADO: 'convocado',
    NO_CONVOCADO: 'no_convocado',
};

export const PLAYER_STATUS_LEVEL = {
    CORTA_DURACION: 1,
    LARGA_DURACION: 2,
    EXTRADEPORTIVO: 3,
};

export const PLAYER_SHAPE = {
    NO_JUEGA: 'No ha jugado recientemente',
    JUEGA_POCO: 'Ha jugado poco recientemente',
    STATUS_MESSI: 'Poseído por Messi',
    STATUS_NICE: 'Modo Saiyajin',
    STATUS_GOOD_BUT_MISSED_GAMES: 'Rendimiento bueno con algunos partidos no jugados recientemente',
    STATUS_GOOD: 'Rendimiento bueno',
    STATUS_ACCEPTABLE_BUT_MISSED_GAMES: 'Rendimiento aceptable con algunos partidos no jugados recientemente',
    STATUS_ACCEPTABLE: 'Rendimiento aceptable',
    STATUS_BAD_BUT_MISSED_GAMES: 'Rendimiento malo con algunos partidos no jugados recientemente',
    STATUS_BAD: 'Rendimiento malo',
    STATUS_VERY_BAD_BUT_MISSED_GAMES: 'Rendimiento muy malo con algunos partidos no jugados recientemente',
    STATUS_VERY_BAD: 'Rendimiento muy malo',
};

export const SQUADS_CONSTANTS = {
    MAX_PLAYERS: 26,
};
