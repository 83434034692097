const HistoricIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 50 50"
            style={{ marginRight: '4px' }}
        >
            <g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                <path stroke="#344054" d="M6.25 25c8.333 0 27.083-4.167 35.417-18.75" />
                <path stroke="#344054" d="M33.333 6.25h8.334l2.083 8.333" />
                <path
                    stroke="#306CFE"
                    d="M12.5 43.75H6.25v-8.333h6.25zm16.667-12.5h-6.25v12.5h6.25zm16.666-8.333h-6.25V43.75h6.25z"
                />
            </g>
        </svg>
    );
};

export default HistoricIcon;
