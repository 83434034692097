const ProfileIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 36 36"
            style={{ marginRight: '4px' }}
        >
            <path
                fill="#269"
                d="M24 26.799v-2.566c2-1.348 4.08-3.779 4.703-6.896c.186.103.206.17.413.17c.991 0 1.709-1.287 1.709-2.873c0-1.562-.823-2.827-1.794-2.865c.187-.674.293-1.577.293-2.735C29.324 5.168 26 .527 18.541.527c-6.629 0-10.777 4.641-10.777 8.507c0 1.123.069 2.043.188 2.755c-.911.137-1.629 1.352-1.629 2.845c0 1.587.804 2.873 1.796 2.873c.206 0 .025-.067.209-.17C8.952 20.453 11 22.885 13 24.232v2.414c-5 .645-12 3.437-12 6.23v1.061C1 35 2.076 35 3.137 35h29.725C33.924 35 35 35 35 33.938v-1.061c0-2.615-6-5.225-11-6.078"
            />
        </svg>
    );
};

export default ProfileIcon;
