import { useContext } from 'react';

import { TwitchConstants } from '@/constants/twitch-constants';
import { Context } from '@/context';

import Tiktok from './icons/tiktok';

const TwitchLive = () => {
    const { state } = useContext(Context);
    const { configuration } = state;

    const styles = {
        height: '50px',
        marginBottom: '16px',
        display: 'flex',
        justifyContent: 'center',
        color: '#fff',
        padding: '0 12px',
        marginTop: '16px',
        marginLeft: '8px',
        marginRight: '8px',
        borderRadius: '4px',
    };

    const handleClick = () => {
        if (configuration?.twitchOption === TwitchConstants.LIVE) {
            window.open('https://www.twitch.tv/donquijotedlc', '_blank');
        } else if (configuration?.twitchOption === TwitchConstants.LIVE_KROMOW) {
            window.open('https://www.twitch.tv/kromow', '_blank');
        } else if (configuration?.twitchOption === TwitchConstants.LIVE_TIKTOK) {
            window.open('https://www.tiktok.com/@analitica.fantasy/live', '_blank');
        }
    };

    if (
        configuration?.twitchOption === TwitchConstants.LIVE ||
        configuration?.twitchOption === TwitchConstants.LIVE_KROMOW
    ) {
        return (
            <div
                style={{
                    ...styles,
                    backgroundColor: '#6441a5',
                }}
                onClick={handleClick}
            >
                <div
                    style={{
                        opacity: 1,
                        transition: 'opacity 0.9s ease-in-out',
                        gap: '6px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="0.96em" height="1em" viewBox="0 0 256 268">
                        <path
                            fill="#fff"
                            d="M17.458 0L0 46.556v186.201h63.983v34.934h34.931l34.898-34.934h52.36L256 162.954V0zm23.259 23.263H232.73v128.029l-40.739 40.741H128L93.113 226.92v-34.886H40.717zm64.008 116.405H128V69.844h-23.275zm63.997 0h23.27V69.844h-23.27z"
                        />
                    </svg>
                    <span>Estamos en directo</span>{' '}
                </div>
            </div>
        );
    } else if (configuration?.twitchOption === TwitchConstants.LIVE_TIKTOK) {
        return (
            <div
                style={{
                    ...styles,
                    backgroundColor: '#080808',
                }}
                onClick={handleClick}
            >
                <div
                    style={{
                        opacity: 1,
                        transition: 'opacity 0.9s ease-in-out',
                        gap: '6px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Tiktok color="#fff" />
                    <span>Estamos en directo</span>{' '}
                </div>
            </div>
        );
    }
};

export default TwitchLive;
