import Head from 'next/head';

import { Pages, Rutas } from '@/constants/rutas';

import GetSeoSchemaOrg from '../SeoSchemaOrg';

const descriptions = {
    [Pages.ESTADISTICAS_FANTASY_JUGADORES]:
        'Mejores jugadores de La Liga Fantasy 2023/2024. Mejores delanteros, centrocampistas, defensas y porteros en clave Fantasy Marca, Biwenger, Comunio, Mister y Futmondo',
    [Pages.MERCADO]:
        'Subidas y bajadas diarias de La Liga Fantasy Relevo. Valor de mercado, tendencia actual, lesionados, sancionados, apercibidos y perfil del jugador en clave fantasy',
    [Pages.MERCADO_EQUIPOS]:
        'Equipos con mayores subidas y bajadas diarias de mercado de La Liga Fantasy Relevo. Descubre los mejores jugadores para especular y ganar tus ligas fantasy.',
    [Pages.NOTICIAS]:
        'Toda la actualidad fantasy de La Liga en clave Fantasy Marca, Biwenger, Comunio, Mister y Futmondo. Noticias, alineaciones probables, entrenamientos, ruedas de prensa y rotaciones',
    [Pages.INICIO]:
        'Analítica Fantasy Fútbol: sitio de referencia para La Liga Fantasy. Alineaciones, estadísticas y mercado. ¡Entra y mejora tu equipo!',
    [Pages.ANALIZA_TU_PLANTILLA]:
        'Análisis de tu plantilla de fútbol para La Liga Fantasy. Consulta rivales, lesionados, sancionados, puntuaciones, recomendaciones y analítica de mercado',
    [Pages.COMPARADOR_DE_JUGADORES]:
        'Compara tus jugadores de fútbol en clave Fantasy. próximos partidos, subidas y bajadas de mercado, estadísticas, mejores rachas y recomendaciones de Analítica Fantasy',
    [Pages.LESIONADOS_SANCIONADOS_APERCIBIDOS]:
        'Lesionados, sancionados y apercibidos en La Liga Fantasy y más. Fíltralos por nombre o equipo en Biwenger, Comunio, Futmondo, Sofascore, Mister',
    [Pages.CLASIFICACION]:
        'Consulta la tabla de posición y clasificación de La Liga: goles a favor y en contra, diferencia de goles, equipos en Champions, Europa League y descenso',
    [Pages.ONCE_IDEAL]:
        'Descubre los mejores jugadores de cada jornada en La Liga Fantasy: el once ideal, el once rentable y el MVP de cada jornada',
    [Pages.LANZADORES_PENALTIS]:
        'Conoce a los jugadores que lanzan penaltis en clave Fútbol Fantasy. Todas las temporadas de La Liga Fantasy Relevo, Biwenger, Marca, Mister Comunio y Futmondo',
    [Pages.ESTADISTICAS_DEL_JUGADOR_DEFAULT]:
        'Descubre estadísticas de jugadores fútbol en clave LaLiga Fantasy: goles, asistencias, regates y más. Mantente al día con noticias, analítica de mercado y lesiones',
    [Pages.LIDERES_POR_JORNADAS]:
        'Líderes en goles, asistencias, regates, pérdidas, puntos fantasy, tiros a puerta, puntos, despejes y paradas de cada jornada en clave LaLiga Fantasy',
    [Pages.MEJORES_RACHAS]:
        'Jugadores de fútbol que mejor puntúan en clave La Liga Fantasy, próximo partido, posición, partidos, media de puntuación y si juegan de local o visitante',
    [Pages.INICIA_SESION]:
        'Analítica Fantasy: tu blog de fútbol especializado en La Liga Fantasy. Ofrecemos alineaciones probables, estadísticas y analítica de jugadores y equipos',
    [Pages.REGISTRATE]:
        'Regístrate en Analítica Fantasy, recurso clave para La Liga Fantasy. Ofrecemos alineaciones probables, analítica de mercado y estadísticas de jugadores y equipos',
    [Pages.MIS_PLANTILLAS]:
        'Crea tus propias plantillas de fútbol La Liga Fantasy, podrás ver sus alineaciones probables, realizar análisis y comparativas de jugadores y mucho más',
    [Pages.EDITAR_PLANTILLA]:
        'Personaliza y ajusta tus plantillas de Fútbol La Liga Fantasy con nuestro editor intuitivo. Optimiza tu alineación y mejora tus resultados en cada jornada',
    [Pages.CREAR_PLANTILLA]:
        'Construye y personaliza tus propias plantillas de Fútbol La Liga Fantasy con nuestro editor de Big Data. Prepárate para utilizar la mejor herramienta fantasy',
    [Pages.TERMINOS_CONDICIONES]:
        'Consulta nuestros términos y condiciones para entender las directrices que rigen el uso de nuestro sitio web Analítica Fantasy de Fútbol La Liga Fantasy',
    [Pages.POLITICAS_PRIVACIDAD]:
        'Descubre nuestra política de privacidad y cómo tratamos tus datos de manera segura y transparente en nuestra web Analítica Fantasy de Fútbol La Liga Fantasy',
    [Pages.CONTACTANOS]:
        'Ponte en contacto con nosotros para cualquier duda o sugerencia que tengas sobre La Liga Fantasy o Analítica Fantasy. Estamos aquí para intentar ayudarte',
    [Pages.PARTIDO]: 'Partido',
    [Pages.EQUIPO]:
        'Alineaciones probables, calendario, analítica de mercado, estadísticas y predicciones en clave Fútbol La Liga Fantasy para los equipos de La Liga EA Sports',
    [Pages.CALENDARIO_FANTASY]:
        'Mejores equipos en clave fútbol fantasy de los próximos partidos de cada equipo de La Liga para La Liga Fantasy, Biwenger, Marca, Mister Comunio y Futmondo',
    [Pages.REESTABLECER_CONTRASENA]:
        'Restablece tu contraseña en Analítica Fantasy. Sigue las instrucciones para recuperar el acceso a tu cuenta y continuar disfrutando de nuestros análisis de La Liga Fantasy',
    [Pages.ANALISIS_PLANTILLA]:
        'Analizador de plantillas de Analítica Fantasy en clave La Liga Fantasy. Recomendaciones, analítica de mercado, alineaciones probables, predicciones y estadísticas',
    [Pages.ENCUESTA_DE_SATISFACCION]:
        'Comparte tu opinión en nuestra encuesta de satisfacción y ayúdanos a mejorar. Tu feedback es muy valioso para nosotros en Analítica Fantasy',
    [Pages.PERFIL_DE_USUARIO]:
        'Accede a tu perfil de usuario en Analítica Fantasy. Personaliza tus preferencias, consulta tus estadísticas y administra tus plantillas de La Liga Fantasy',
    [Pages.OLVIDASTE_TU_CONTRASENA]:
        '¿Olvidaste tu contraseña? No te preocupes, en Analítica Fantasy te ayudamos a recuperarla. Sigue los pasos para reestablecer tu contraseña y acceder de nuevo',
    [Pages.FANTASY_MANAGER]:
        'Bienvenido al Fantasy Manager de Analítica Fantasy, donde podrás crear y administrar tus plantillas basadas en estadísticas actualizadas y análisis detallados para dominar tu liga',
    [Pages.PREDICTING_MANAGER]:
        'Muestra tu habilidad para predecir las alineaciones de La Liga y compite por ganar premios. ¡Demuestra que eres el mejor estratega del fútbol fantasy!',
    [Pages.PREDICTING_MANAGER_RANKING]:
        'Clasificación de los mejores predictores de alineaciones de Fútbol Fantasy. Compite y demuestra tu conocimiento futbolístico en nuestro ranking de Analítica Fantasy',
    [Pages.ADMINISTRADOR_JUGADORES]:
        'Gestiona tus jugadores con el administrador de Analítica Fantasy. Sigue sus estadísticas, valor de mercado, y planifica tu estrategia para dominar en La Liga Fantasy',
    [Pages.PREDICTING_MANAGER_ALINEACIONES]:
        'Consulta las alineaciones probables de nuestros managers cada jornada de Fútbol Fantasy en La Liga EA Sports. Predicciones actualizadas minuto a minuto',
    [Pages.PREDICTING_MANAGER_RULES]:
        'Conoce las reglas del juego de Predicting Manager en Analítica Fantasy. Aprende cómo participar y conviértete en el mejor predictor de alineaciones Fútbol Fantasy',
    [Pages.COLABORADORES]:
        'Conoce nuestros colaboradores en clave Fútbol Fantasy y descubre cómo puedes formar parte de Analítica Fantasy. ¡Únete a nuestro equipo y ayúdanos a mejorar!',
    [Pages.COLABORADORES_NOTICIAS]:
        'Conoce nuestros colaboradores en clave Fútbol Fantasy y descubre cómo puedes formar parte de Analítica Fantasy. ¡Únete a nuestro equipo y ayúdanos a mejorar!',
    [Pages.JUGADORES]:
        'Descubre a los jugadores de fútbol más destacados de La Liga Fantasy. Consulta sus estadísticas, valor de mercado, alineaciones probables y mucho más',
    [Pages.PUJA_FANTASY]:
        'Descubre la puja ideal para cada jugador de La Liga Fantasy. Analítica Fantasy te ofrece la mejor estrategia para dominar tu liga de fútbol fantasy',
    [Pages.PUJA_FANTASY_BIWENGER]:
        'Descubre la puja ideal para cada jugador de Fantasy Biwenger. Analítica Fantasy te ofrece la mejor estrategia para dominar tu liga de fútbol fantasy',
    [Pages.QUINIELA_JUEGO]:
        'Demuestra tus habilidades de fantasy jugando nuestra Quiniela Analítica y compite cada jornada en un ranking global por diferentes premios',
    [Pages.QUINIELA_CLASIFICACION]:
        'Consulta la tabla de posición y clasificación de la Quiniela Analítica: ranking, puntuación y redes sociales de los mánagers',
    [Pages.QUINIELA_REGLAS_DEL_JUEGO]:
        'Conoce las reglas del juego de la Quiniela Analítica de Analítica Fantasy. Vota cada jornada y demuestra que puedes convertirte en el mejor manager y ganar premios',
    [Pages.JUGADORES_LESIONADOS]:
        'Conoce los jugadores lesionados de todos los equipos en cada jornada de La Liga Fantasy en clave Fútbol La Liga Fantasy, Biwenger, Marca, Mister Comunio y Futmondo',
    [Pages.JUGADORES_SANCIONADOS]:
        'Conoce los jugadores sancionados de todos los equipos en cada jornada de La Liga Fantasy en clave Fútbol La Liga Fantasy, Biwenger, Marca, Mister Comunio y Futmondo',
    [Pages.JUGADORES_DUDAS]:
        'Conoce los jugadores en dudas de todos los equipos en cada jornada de La Liga Fantasy en clave Fútbol La Liga Fantasy, Biwenger, Marca, Mister Comunio y Futmondo',
    [Pages.JUGADORES_APERCIBIDOS]:
        'Conoce los jugadores en apercibidos de todos los equipos en cada jornada de La Liga Fantasy en clave Fútbol La Liga Fantasy, Biwenger, Marca, Mister Comunio y Futmondo',
    [Pages.NOTICIAS_FLASH]:
        'Noticias de fantasy fútbol de los jugadores de La Liga en clave Liga Fantasy, Biwenger, Marca, Mister Comunio y Futmondo. Recomendaciones, análisis y alineaciones probables',
    [Pages.CALENDARIO_COMPETICIONES]:
        'Calendario de competiciones de fútbol en clave La Liga Fantasy. Consulta los próximos partidos de cada equipo de La Liga. Champions League, Europa League, Mundial, Eurocopa y otras competiciones',
    [Pages.CONVOCATORIAS]:
        'Consulta la convocatoria de cada partido de fútbol en clave La Liga Fantasy. Conoce los jugadores convocados y los descartes de cada equipo',
    [Pages.VIRUS_FIFA]:
        'Jugadores de fútbol fantasy convocados por sus selecciones en clave La Liga Fantasy Relevo, Marca, Biwenger, Mister, Futmondo. Recomendación fantasy y beneficiados',
    [Pages.APUESTAS_DEPORTIVAS]:
        'Apuestas deportivas en clave La Liga Fantasy. Consulta los pronósticos de los partidos de fútbol de La Liga y otras competiciones',
    [Pages.MERCADO_FICHAJES]:
        'Rumores, fichajes confirmados y todas las novedades del mercado de fichajes de fútbol de La Liga',
    [Pages.MERCADO_BIWENGER]:
        'Subidas y bajadas diarias de mercado de Fantasy Biwenger. Tendencias, valor de mercado, alineaciones probables, estadisticas y jugadores en clave Fantasy Biwenger',
    [Pages.ESTADISTICAS_BIWENGER]:
        'Estadísticas de jugadores de fútbol en clave Fantasy Biwenger. Goles, asistencias, regates, atajadas, recuperaciones y más. ¡Fútbol Analytics en clave Fantasy Biwenger!',
    [Pages.LIDERES_ESTADISTICAS]:
        'Líderes de estadísticas de La Liga Fantasy. Jugadores con mejor promedio de puntuación, goles, asistencias, regates, atajadas, recuperaciones de fantasy fútbol',
    [Pages.INSTALL_APP]:
        'Descarga nuestra app de Analítica Fantasy para disfrutar de todas las funcionalidades en tu dispositivo móvil. ¡No te pierdas ninguna novedad de La Liga Fantasy!',
    [Pages.PREMIOS_Y_SORTEOS]:
        'Participa en nuestros premios y sorteos de fantasy fútbol en Analítica Fantasy. ¡Gana premios exclusivos y demuestra que eres el mejor manager de La Liga Fantasy!',
    [Pages.ACTIVIDADES]:
        'Todos los eventos y actividades de cada día en clave fantasy fútbol, 11 posibles, lesionados, sancionados, apercibidos, noticias y estadísticas de La Liga Fantasy',
    [Pages.SALON_DE_LA_FAMA]:
        'Salón de la fama de Analítica Fantasy. Conoce a los managers más destacados de las competiciones fantasy organizadas por Analítica Fantasy',
    [Pages.CHOLLOS_FANTASY_BIWENGER]:
        'Descubre los mejores chollos en clave fantasy biwenger de cada jornada de la Eurocopa 2024. Titulares con menor valor de mercado y dificultad de partido.',
    [Pages.CHOLLOS_FANTASY_RELEVO]:
        'Descubre los mejores chollos en clave la liga fantasy relevo de cada jornada de La Liga 2024/2024. Titulares con menor valor de mercado y dificultad de partido.',
    [Pages.QUEDADA_FANTASY]:
        'Conoce toda la información sobre la primera quedada fantasy organizada en la historia. La Tertulia Sports, Marca, Abogados Legalsha y Analítica Fantasy patrocinaron el evento',
    [Pages.TIER_LIST]:
        'Crea tus propias tierlist de jugadores de fútbol en clave fantasy. Crea, edita y comparte tus tierlist de jugadores de La Liga Fantasy, Biwenger, Marca y Mister',
    [Pages.NUEVA_TIER_LIST]:
        'Crea tu propia tierlist de jugadores de fútbol en clave fantasy. Crea, edita y comparte tus tierlist de jugadores de La Liga Fantasy, Biwenger, Marca y Mister',
    [Pages.EDIT_TIER_LIST]:
        'Edita tu tierlist de jugadores de fútbol en clave fantasy. Crea, edita y comparte tus tierlist de jugadores de La Liga Fantasy, Biwenger, Marca y Mister',
    [Pages.VISUALIZAR_TIER_LIST]:
        'Visualiza tu tierlist de jugadores de fútbol en clave fantasy. Crea, edita y comparte tus tierlist de jugadores de La Liga Fantasy, Biwenger, Marca y Mister',
    [Pages.FANTASY_RELEVO_FALLECIO]:
        'Fantasy La Liga ha decidido tomarse un descanso por vacaciones de Agosto. ¡Disfruta del mundo real mientras los servidores vuelven de sus vacaciones!',
    [Pages.TIER_LIST_PUBLIC]:
        'Visualiza las tierlists públicas de Analítica Fantasy en clave fantasy fútbol. Descubre las tierlists de jugadores de La Liga Fantasy, Biwenger, Marca y Mister',
    [Pages.KIT_DIGITAL]:
        'Conoce el Kit Digital de Analítica Fantasy. Descubre las métricas clave como visitas mensuales, usuarios activos, tiempo de interacción, páginas más visitadas y mucho más en clave fantasy fútbol',
    [Pages.ORACULO_FANTASY]:
        'Oráculo Fantasy de Analítica Fantasy. Descubre los mejores jugadores de La Liga Fantasy 2024/2025. Mejores delanteros, centrocampistas, defensas y porteros en clave Fantasy Marca, Biwenger, Comunio, Mister y Futmondo',
    [Pages.SITIOS_RECOMENDADOS]:
        'Sitios recomendados de Analítica Fantasy. Descubre los mejores sitios de fútbol fantasy en clave Fantasy Marca, Biwenger, Comunio, Mister y Futmondo',
    [Pages.CREADORES_CONTENIDO]:
        'Conoce a los creadores de contenido en Analítica Fantasy. Descubre a los mejores creadores de contenido de fútbol fantasy en clave Fantasy Marca, Biwenger, Comunio, Mister y Futmondo',
    [Pages.PUNTUACIONES_FANTASY]:
        'Mejores jugadores de La Liga Fantasy Relevo de cada jornada. Conoce sus puntos en tiempo real y descubre los jugadores más rentables de cada jornada divididos por tier',
    [Pages.PREPARADOR_JORNADA]:
        'Prepara tu jornada de La Liga Fantasy con Analítica Fantasy. Descubre los mejores jugadores de cada jornada, alineaciones probables, lesionados, sancionados y mucho más',
    [Pages.ALINEACIONES_PROBABLES]:
        'Alineaciones probables de La Liga para Fantasy Fútbol. Actualizaciones en tiempo real ante lesiones, sanciones o rotaciones. ¡Optimiza tu equipo ahora!',
    [Pages.NOVEDADES]:
        'Conoce todas las nuevas funcionalidades de Analítica Fantasy que te ayudarán a ganar tus ligas fantasy. Descubre lo nuevo de la mejor web de fantasy fútbol',
    [Pages.PREMIUM]:
        'Descubre las ventajas de ser usuario premium en Analítica Fantasy. Accede a contenido exclusivo, estadísticas avanzadas y herramientas para mejorar tu rendimiento en La Liga Fantasy',
};

const titles = {
    [Pages.ESTADISTICAS_FANTASY_JUGADORES]: 'Estadísticas de Fútbol de La Liga Fantasy',
    [Pages.MERCADO]: 'Subidas y bajadas de valor de mercado diarias de La Liga Fantasy Relevo',
    [Pages.MERCADO_EQUIPOS]: 'Subidas y bajadas de mercado de equipos de La Liga Fantasy Relevo',
    [Pages.INICIO]: 'Alineaciones probables - Liga Fantasy Fútbol - Analítica Fantasy',
    [Pages.NOTICIAS]: 'Noticias de fantasy fútbol, actualidad de mercado y análisis de jugadores',
    [Pages.ANALIZA_TU_PLANTILLA]: 'Análisis de plantillas en clave Fútbol La Liga Fantasy',
    [Pages.COMPARADOR_DE_JUGADORES]: 'Comparador de jugadores de fútbol en clave fantasy',
    [Pages.LESIONADOS_SANCIONADOS_APERCIBIDOS]:
        'Jugadores Lesionados, Sancionados y Apercibidos en Clave Fantasy',
    [Pages.CLASIFICACION]: 'Clasificación de Equipos de La Liga: Posición, Goles y Más',
    [Pages.ONCE_IDEAL]: 'Once ideal, rentable y MVP en clave La Liga Fantasy Relevo',
    [Pages.LANZADORES_PENALTIS]: 'Estadísticas de pateadores de penalti de La Liga',
    [Pages.ESTADISTICAS_DEL_JUGADOR_DEFAULT]: 'Estadísticas de jugadores de fútbol en clave La Liga Fantasy',
    [Pages.LIDERES_POR_JORNADAS]: 'Líderes en estadísticas, goles, asistencias, puntos Fantasy',
    [Pages.MEJORES_RACHAS]: 'Mejores rachas de puntos fantasy en clave La Liga Fantasy Fútbol',
    [Pages.INICIA_SESION]: 'Iniciar sesión en Analitica Fantasy',
    [Pages.REGISTRATE]: 'Regístrarse en Analitica Fantasy',
    [Pages.MIS_PLANTILLAS]: 'Mis Plantillas de Fútbol La Liga Fantasy',
    [Pages.EDITAR_PLANTILLA]: 'Editar Plantilla de Fútbol La Liga Fantasy',
    [Pages.CREAR_PLANTILLA]: 'Crear Plantillas de La Liga Fantasy, Fantasy Marca, Biwenger',
    [Pages.TERMINOS_CONDICIONES]: 'Términos y condiciones de Analitica Fantasy',
    [Pages.POLITICAS_PRIVACIDAD]: 'Política de privacidad de Analitica Fantasy',
    [Pages.CONTACTANOS]: 'Contáctanos y te ayudaremos en Analitica Fantasy',
    [Pages.PARTIDO]: 'PARTIDO | Analitica Fantasy',
    [Pages.CALENDARIO_FANTASY]: 'Equipos con mejor calendario para La Liga Fantasy',
    [Pages.REESTABLECER_CONTRASENA]: 'Reestablecer contraseña de Analitica Fantasy',
    [Pages.ANALISIS_PLANTILLA]: 'Análisis Plantilla de La Liga Fantasy, Fantasy Marca, Biwenger',
    [Pages.ENCUESTA_DE_SATISFACCION]: 'Encuesta de satisfacción de Analítica Fantasy',
    [Pages.PERFIL_DE_USUARIO]: 'Perfil de usuario de Analítica Fantasy',
    [Pages.OLVIDASTE_TU_CONTRASENA]: '¿Olvidaste tu contraseña? en Analítica Fantasy',
    [Pages.FANTASY_MANAGER]: 'Fantasy Manager de La Liga Fantasy, Fantasy Marca, Biwenger',
    [Pages.PREDICTING_MANAGER]: 'Predicting Manager de Fútbol La Liga Fantasy | Analítica Fantasy',
    [Pages.PREDICTING_MANAGER_RANKING]: 'Ranking de managers de Predicting Manager | Analítica Fantasy',
    [Pages.ADMINISTRADOR_JUGADORES]: 'Administrador de jugadores de Analítica Fantasy',
    [Pages.PREDICTING_MANAGER_ALINEACIONES]: 'Alineaciones probables de los managers de Predicting Manager',
    [Pages.PREDICTING_MANAGER_RULES]: 'Reglas del Predicting Manager de fútbol de Analítica Fantasy',
    [Pages.COLABORADORES]: 'Colaboradores de Analítica Fantasy',
    [Pages.COLABORADORES_NOTICIAS]: 'Noticias de colaboradores de Analítica Fantasy',
    [Pages.JUGADORES]: 'Perfil del jugador en clave Fantasy. | Analítica Fantasy',
    [Pages.QUINIELA_JUEGO]: 'Juega la Quiniela Analítica | Analítica Fantasy',
    [Pages.PUJA_FANTASY]: 'Puja ideal para cada jugador de La Liga Fantasy | Analítica Fantasy',
    [Pages.PUJA_FANTASY_BIWENGER]: 'Puja ideal para cada jugador de Fantasy Biwenger | Analítica Fantasy',
    [Pages.QUINIELA_CLASIFICACION]: 'Clasificación de la Quiniela Analítica | Analítica Fantasy',
    [Pages.QUINIELA_REGLAS_DEL_JUEGO]: 'Reglas del juego de la Quiniela Analítica de Analítica Fantasy',
    [Pages.JUGADORES_LESIONADOS]: 'Jugadores lesionados de La Liga Fantasy | Analítica Fantasy',
    [Pages.JUGADORES_SANCIONADOS]: 'Jugadores sancionados de La Liga Fantasy | Analítica Fantasy',
    [Pages.JUGADORES_DUDAS]: 'Jugadores en dudas de La Liga Fantasy | Analítica Fantasy',
    [Pages.JUGADORES_APERCIBIDOS]: 'Jugadores apercibidos de La Liga Fantasy | Analítica Fantasy',
    [Pages.NOTICIAS_FLASH]: 'Noticias flash para cubrir la actualidad más inmediata | Analítica Fantasy',
    [Pages.CALENDARIO_COMPETICIONES]: 'Calendario de competiciones de fútbol | Analítica Fantasy',
    [Pages.CONVOCATORIAS]: 'Convocatoria del partido | Analítica Fantasy',
    [Pages.VIRUS_FIFA]: 'Jugadores de fantasy fútbol convocados por sus selecciones',
    [Pages.APUESTAS_DEPORTIVAS]: 'Apuestas deportivas | Analítica Fantasy',
    [Pages.MERCADO_FICHAJES]: 'Mercado de fichajes de fùtbol de La Liga',
    [Pages.MERCADO_BIWENGER]: 'Subidas y bajadas diarias de mercado de Fantasy Biwenger',
    [Pages.ESTADISTICAS_BIWENGER]: 'Estadísticas de jugadores de fútbol en clave Fantasy Biwenger',
    [Pages.LIDERES_ESTADISTICAS]: 'Líderes de estadísticas de La Liga Fantasy',
    [Pages.INSTALL_APP]: 'Descarga nuestra app de Analítica Fantasy',
    [Pages.PREMIOS_Y_SORTEOS]: 'Premios y sorteos de fantasy fútbol en Analítica Fantasy',
    [Pages.ACTIVIDADES]: 'Resumen de fantasy fútbol en Analítica Fantasy',
    [Pages.SALON_DE_LA_FAMA]: 'Salón de la fama de Analítica Fantasy',
    [Pages.CHOLLOS_FANTASY_BIWENGER]: 'Chollos fantasy de Biwenger',
    [Pages.CHOLLOS_FANTASY_RELEVO]: 'Chollos de La Liga Fantasy Relevo',
    [Pages.QUEDADA_FANTASY]: 'Quedada Fantasy 2024 | Analítica Fantasy',
    [Pages.TIER_LIST]: 'Gestiona tus tierlist de jugadores de fantasy fútbol',
    [Pages.TIER_LIST_PUBLIC]: 'Consulta las tierlists de Analítica Fantasy',
    [Pages.NUEVA_TIER_LIST]: 'Crea tu tierlist de jugadores de fantasy fútbol',
    [Pages.EDIT_TIER_LIST]: 'Edita tu tierlist de jugadores de fantasy fútbol',
    [Pages.VISUALIZAR_TIER_LIST]: 'Visualiza tu tierlist de jugadores de fantasy fútbol',
    [Pages.FANTASY_RELEVO_FALLECIO]: 'Fantasy Relevo estiró la pata',
    [Pages.KIT_DIGITAL]: 'Kit Digital de Analítica Fantasy',
    [Pages.ORACULO_FANTASY]: 'Oráculo de La Liga Fantasy',
    [Pages.SITIOS_RECOMENDADOS]: 'Sitios recomendados de Analítica Fantasy',
    [Pages.CREADORES_CONTENIDO]: 'Creadores de contenido en Analítica Fantasy',
    [Pages.PUNTUACIONES_FANTASY]: 'Puntuaciones de LaLiga Fantasy de cada jornada',
    [Pages.PREPARADOR_JORNADA]: 'Preparador de Jornada de La Liga Fantasy',
    [Pages.ALINEACIONES_PROBABLES]: 'Alineaciones probables de La Liga Fantasy: Jornada actual',
    [Pages.NOVEDADES]: 'Últimas Novedades de Analítica Fantasy',
    [Pages.PREMIUM]: 'Bienvenidos a la experiencia Premium de Analítica Fantasy',
};

const urls = {
    [Pages.ESTADISTICAS_FANTASY_JUGADORES]: Rutas.ESTADISTICAS_FANTASY_JUGADORES,
    [Pages.MERCADO]: Rutas.MERCADO,
    [Pages.MERCADO_EQUIPOS]: Rutas.MERCADO_EQUIPOS,
    [Pages.NOTICIAS]: Rutas.NOTICIAS,
    [Pages.INICIO]: Rutas.INICIO,
    [Pages.ANALIZA_TU_PLANTILLA]: Rutas.ANALIZA_TU_PLANTILLA,
    [Pages.COMPARADOR_DE_JUGADORES]: Rutas.COMPARADOR_DE_JUGADORES,
    [Pages.LESIONADOS_SANCIONADOS_APERCIBIDOS]: Rutas.LESIONADOS_SANCIONADOS_APERCIBIDOS,
    [Pages.CLASIFICACION]: Rutas.CLASIFICACION,
    [Pages.ONCE_IDEAL]: Rutas.ONCE_IDEAL,
    [Pages.ESTADISTICAS_DEL_JUGADOR_DEFAULT]: Rutas.ESTADISTICAS_DEL_JUGADOR_DEFAULT,
    [Pages.MEJORES_RACHAS]: Rutas.MEJORES_RACHAS,
    [Pages.INICIA_SESION]: Rutas.INICIA_SESION,
    [Pages.REGISTRATE]: Rutas.REGISTRATE,
    [Pages.MIS_PLANTILLAS]: Rutas.MIS_PLANTILLAS,
    [Pages.EDITAR_PLANTILLA]: Rutas.EDITAR_PLANTILLA,
    [Pages.CREAR_PLANTILLA]: Rutas.CREAR_PLANTILLA,
    [Pages.TERMINOS_CONDICIONES]: Rutas.TERMINOS_CONDICIONES,
    [Pages.POLITICAS_PRIVACIDAD]: Rutas.POLITICAS_PRIVACIDAD,
    [Pages.CONTACTANOS]: Rutas.CONTACTANOS,
    [Pages.PARTIDO]: Rutas.PARTIDO,
    [Pages.CALENDARIO_FANTASY]: Rutas.CALENDARIO_FANTASY,
    [Pages.REESTABLECER_CONTRASENA]: Rutas.REESTABLECER_CONTRASENA,
    [Pages.ANALISIS_PLANTILLA]: Rutas.ANALIZA_TU_PLANTILLA,
    [Pages.ENCUESTA_DE_SATISFACCION]: Rutas.ENCUESTA_DE_SATISFACCION,
    [Pages.PERFIL_DE_USUARIO]: Rutas.PERFIL_DE_USUARIO,
    [Pages.OLVIDASTE_TU_CONTRASENA]: Rutas.OLVIDASTE_TU_CONTRASENA,
    [Pages.FANTASY_MANAGER]: Rutas.FANTASY_MANAGER,
    [Pages.PREDICTING_MANAGER]: Rutas.PREDICTING_MANAGER,
    [Pages.PREDICTING_MANAGER_RANKING]: Rutas.PREDICTING_MANAGER_RANKING,
    [Pages.ADMINISTRADOR_JUGADORES]: Rutas.ADMINISTRADOR_JUGADORES,
    [Pages.PREDICTING_MANAGER_ALINEACIONES]: Rutas.PREDICTING_MANAGER_ALINEACIONES,
    [Pages.PREDICTING_MANAGER_RULES]: Rutas.PREDICTING_MANAGER_RULES,
    [Pages.COLABORADORES]: Rutas.COLABORADORES,
    [Pages.COLABORADORES_NOTICIAS]: Rutas.COLABORADORES_NOTICIAS,
    [Pages.JUGADORES]: Rutas.JUGADORES,
    [Pages.QUINIELA_JUEGO]: Rutas.QUINIELA_JUEGO,
    [Pages.PUJA_FANTASY]: Rutas.PUJA_FANTASY,
    [Pages.PUJA_FANTASY_BIWENGER]: Rutas.PUJA_FANTASY_BIWENGER,
    [Pages.QUINIELA_CLASIFICACION]: Rutas.QUINIELA_CLASIFICACION,
    [Pages.QUINIELA_REGLAS_DEL_JUEGO]: Rutas.QUINIELA_REGLAS_DEL_JUEGO,
    [Pages.JUGADORES_LESIONADOS]: Rutas.JUGADORES_LESIONADOS,
    [Pages.JUGADORES_SANCIONADOS]: Rutas.JUGADORES_SANCIONADOS,
    [Pages.JUGADORES_DUDAS]: Rutas.JUGADORES_DUDAS,
    [Pages.JUGADORES_APERCIBIDOS]: Rutas.JUGADORES_APERCIBIDOS,
    [Pages.NOTICIAS_FLASH]: Rutas.NOTICIAS_FLASH,
    [Pages.CALENDARIO_COMPETICIONES]: Rutas.CALENDARIO_COMPETICIONES,
    [Pages.CONVOCATORIAS]: Rutas.CONVOCATORIAS,
    [Pages.VIRUS_FIFA]: Rutas.VIRUS_FIFA,
    [Pages.APUESTAS_DEPORTIVAS]: Rutas.APUESTAS_DEPORTIVAS,
    [Pages.MERCADO_FICHAJES]: Rutas.MERCADO_FICHAJES,
    [Pages.MERCADO_BIWENGER]: Rutas.MERCADO_BIWENGER,
    [Pages.ESTADISTICAS_BIWENGER]: Rutas.ESTADISTICAS_BIWENGER,
    [Pages.LIDERES_ESTADISTICAS]: Rutas.LIDERES_ESTADISTICAS,
    [Pages.INSTALL_APP]: Rutas.INSTALL_APP,
    [Pages.PREMIOS_Y_SORTEOS]: Rutas.PREMIOS_Y_SORTEOS,
    [Pages.ACTIVIDADES]: Rutas.ACTIVIDADES,
    [Pages.SALON_DE_LA_FAMA]: Rutas.SALON_DE_LA_FAMA,
    [Pages.CHOLLOS_FANTASY_BIWENGER]: Rutas.CHOLLOS_FANTASY_BIWENGER,
    [Pages.CHOLLOS_FANTASY_RELEVO]: Rutas.CHOLLOS_FANTASY_RELEVO,
    [Pages.QUEDADA_FANTASY]: Rutas.QUEDADA_FANTASY,
    [Pages.LANZADORES_PENALTIS]: Rutas.LANZADORES_PENALTIS,
    [Pages.TIER_LIST]: Rutas.TIER_LIST,
    [Pages.TIER_LIST_PUBLIC]: Rutas.TIER_LIST_PUBLIC,
    [Pages.NUEVA_TIER_LIST]: Rutas.NUEVA_TIER_LIST,
    [Pages.EDIT_TIER_LIST]: Rutas.EDIT_TIER_LIST,
    [Pages.VISUALIZAR_TIER_LIST]: Rutas.VISUALIZAR_TIER_LIST,
    [Pages.EDIT_TIER_LIST]: Rutas.EDIT_TIER_LIST,
    [Pages.FANTASY_RELEVO_FALLECIO]: Rutas.FANTASY_RELEVO_FALLECIO,
    [Pages.KIT_DIGITAL]: Rutas.KIT_DIGITAL,
    [Pages.ORACULO_FANTASY]: Rutas.ORACULO_FANTASY,
    [Pages.SITIOS_RECOMENDADOS]: Rutas.SITIOS_RECOMENDADOS,
    [Pages.CREADORES_CONTENIDO]: Rutas.CREADORES_CONTENIDO,
    [Pages.PUNTUACIONES_FANTASY]: Rutas.PUNTUACIONES_FANTASY,
    [Pages.PREPARADOR_JORNADA]: Rutas.PREPARADOR_JORNADA,
    [Pages.ALINEACIONES_PROBABLES]: Rutas.ALINEACIONES_PROBABLES,
    [Pages.NOVEDADES]: Rutas.NOVEDADES,
    [Pages.PREMIUM]: Rutas.PREMIUM,
};

const images = {
    [Pages.ESTADISTICAS_FANTASY_JUGADORES]: `2024/secciones/estadisticas-fantasy.jpg`,
    [Pages.MERCADO]: `2024/secciones/mercado-laliga.jpg`,
    [Pages.MERCADO_EQUIPOS]: `2024/secciones/mercado-laliga.jpg`,
    [Pages.NOTICIAS]: `2024/secciones/noticias.jpg`,
    [Pages.INICIO]: `2024/secciones/inicio.jpg`,
    [Pages.ANALIZA_TU_PLANTILLA]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.COMPARADOR_DE_JUGADORES]: `2024/secciones/comparador-jugadores.jpg`,
    [Pages.LESIONADOS_SANCIONADOS_APERCIBIDOS]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.CLASIFICACION]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.ONCE_IDEAL]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.LANZADORES_PENALTIS]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.ESTADISTICAS_DEL_JUGADOR_DEFAULT]: `2024/secciones/estadisticas-fantasy.jpg`,
    [Pages.LIDERES_POR_JORNADAS]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.MEJORES_RACHAS]: `2024/secciones/mejores-rachas.jpg`,
    [Pages.INICIA_SESION]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.REGISTRATE]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.MIS_PLANTILLAS]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.EDITAR_PLANTILLA]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.CREAR_PLANTILLA]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.TERMINOS_CONDICIONES]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.POLITICAS_PRIVACIDAD]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.CONTACTANOS]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.PARTIDO]: `partido.jpg`,
    [Pages.CALENDARIO_FANTASY]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.REESTABLECER_CONTRASENA]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.ANALISIS_PLANTILLA]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.ENCUESTA_DE_SATISFACCION]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.PERFIL_DE_USUARIO]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.OLVIDASTE_TU_CONTRASENA]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.FANTASY_MANAGER]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.PREDICTING_MANAGER]: `predicting-manager.jpg`,
    [Pages.PREDICTING_MANAGER_RANKING]: `predicting-manager.jpg`,
    [Pages.ADMINISTRADOR_JUGADORES]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.PREDICTING_MANAGER_ALINEACIONES]: `predicting-manager.jpg`,
    [Pages.PREDICTING_MANAGER_RULES]: `predicting-manager.jpg`,
    [Pages.EQUIPO]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.ALINEACIONES_PROBABLES]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.COLABORADORES]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.COLABORADORES_NOTICIAS]: `2024/secciones/noticias.jpg`,
    [Pages.JUGADORES]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.QUINIELA_JUEGO]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.PUJA_FANTASY]: `2024/secciones/puja-ideal.jpg`,
    [Pages.PUJA_FANTASY_BIWENGER]: `2024/secciones/puja-ideal.jpg`,
    [Pages.QUINIELA_CLASIFICACION]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.QUINIELA_REGLAS_DEL_JUEGO]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.JUGADORES_LESIONADOS]: `2024/secciones/estado-jugadores.jpg`,
    [Pages.JUGADORES_SANCIONADOS]: `2024/secciones/estado-jugadores.jpg`,
    [Pages.JUGADORES_DUDAS]: `2024/secciones/estado-jugadores.jpg`,
    [Pages.NOTICIAS_FLASH]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.CALENDARIO_COMPETICIONES]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.JUGADORES_APERCIBIDOS]: `2024/secciones/estado-jugadores.jpg`,
    [Pages.CONVOCATORIAS]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.VIRUS_FIFA]: `virus-fifa.png`,
    [Pages.APUESTAS_DEPORTIVAS]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.MERCADO_FICHAJES]: `2024/secciones/mercado-laliga.jpg`,
    [Pages.MERCADO_BIWENGER]: `2024/secciones/mercado-laliga.jpg`,
    [Pages.ESTADISTICAS_BIWENGER]: `2024/secciones/estadisticas-fantasy.jpg`,
    [Pages.LIDERES_ESTADISTICAS]: `2024/secciones/estadisticas-fantasy.jpg`,
    [Pages.INSTALL_APP]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.PREMIOS_Y_SORTEOS]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.ACTIVIDADES]: `2024/secciones/actividades.jpg`,
    [Pages.SALON_DE_LA_FAMA]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.CHOLLOS_FANTASY_BIWENGER]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.CHOLLOS_FANTASY_RELEVO]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.QUEDADA_FANTASY]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.TIER_LIST]: `2024/secciones/tierlist.jpg`,
    [Pages.TIER_LIST_PUBLIC]: `2024/secciones/tierlist.jpg`,
    [Pages.EDIT_TIER_LIST]: `2024/secciones/tierlist.jpg`,
    [Pages.NUEVA_TIER_LIST]: `2024/secciones/tierlist.jpg`,
    [Pages.VISUALIZAR_TIER_LIST]: `2024/secciones/tierlist.jpg`,
    [Pages.FANTASY_RELEVO_FALLECIO]: `fantasy-la-liga-down.webp`,
    [Pages.KIT_DIGITAL]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.ORACULO_FANTASY]: `2024/secciones/oraculo-fantasy.jpg`,
    [Pages.SITIOS_RECOMENDADOS]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.CREADORES_CONTENIDO]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.PUNTUACIONES_FANTASY]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.PREPARADOR_JORNADA]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.NOVEDADES]: `2024/secciones/alineaciones-probables.jpg`,
    [Pages.PREMIUM]: `2024/secciones/alineaciones-probables.jpg`,
};

function Seo({ pageId, description, title, url, image, additionalData, summary = 'summary_large_image' }) {
    url = url ?? `${process.env.NEXT_PUBLIC_URL_FRONT}${urls[pageId]}`;
    title = title ?? titles[pageId];
    description = description ?? descriptions[pageId];
    let route = images?.[pageId];
    if (route && route?.length > 0 && route.startsWith('2024')) {
        image = `${process.env.NEXT_PUBLIC_IMAGES}/${images?.[pageId]}`;
    } else {
        image =
            image ??
            `${process.env.NEXT_PUBLIC_IMAGES}/banners/${
                images?.[pageId] ?? '2024/secciones/alineaciones-probables.jpg'
            }`;
    }
    if (!image.includes('width=')) {
        image += `?width=770&height=440`;
    }

    const seoSchema = GetSeoSchemaOrg(pageId, title, url, description, image, additionalData);

    return (
        <Head>
            <title>{title}</title>
            <link rel="canonical" href={url} key="canonical" />

            <meta name="theme-color" content="#ffffff" />
            <meta property="og:locale" content="es_ES" />
            <meta property="og:type" content="fantasy sports" key="type" />
            <meta property="og:site_name" content="AnalíticaFantasy" />
            <meta name="description" content={description} key="name-description" />
            <meta property="og:url" content={url} key="url" />
            <meta property="og:title" content={title} key="title" />
            <meta property="og:description" content={description} key="description" />
            <meta property="og:image:secure_url" content={image} key="image" />

            <meta name="twitter:card" content={summary} key="twitter-card" />
            <meta name="twitter:image:alt" content="analítica fantasy" key="twitter-card-image-alt" />
            <meta name="twitter:site" content="https://www.analiticafantasy.com" key="website" />
            <meta name="twitter:site:id" content="@AnaliticaLaLiga" key="website-twitter" />
            <meta name="twitter:creator" content="@AnaliticaLaLiga" key="twitter-creator" />
            <meta name="twitter:image" content={image} key="twitter-image" />
            <meta name="twitter:title" content={title} key="twitter-title" />
            <meta name="twitter:description" content={description} key="twitter-description" />

            <meta property="og:image:width" content="770" />
            <meta property="og:image:height" content="440" />

            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5, user-scalable=yes"
                key="viewport"
            />

            <meta
                name="robots"
                content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
            />

            {seoSchema}
        </Head>
    );
}

export default Seo;
