import { REDUCERS } from '@/constants/reducers';

export function onceTipo(state, action) {
    switch (action.type) {
        case REDUCERS.UPDATE_ONCE_TIPO:
            const onceTipo = [...state.onceTipo];
            onceTipo.push(action.payload);
            return {
                ...state,
                onceTipo: onceTipo,
            };
        default:
            return state;
    }
}
